import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import { getDeliveryRouteAssignmentNameFromKey } from 'core/constants/delivery-route-assignment';
import { DeliveryStatus, getDeliveryStatusNameFromKey } from 'core/constants/delivery-status';
import { getDeliveryTypeNameFromKey } from 'core/constants/delivery-types';
import { getSharpsBinOptionNameFromKey } from 'core/constants/sharps-bin-options';
import { getStorageRequirementNameFromKey } from 'core/constants/storage-requirement';
import { TimeWindow, getTimeWindowNameFromKey } from 'core/constants/time-window';
import { getWasteCollectionNameFromKey } from 'core/constants/waste-collection';
import { useDialog } from 'core/providers/DialogProvider';
import { createAddressString } from 'core/utilities/address-string-builder';
import { getToday } from 'core/utilities/date-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import dayjs from 'dayjs';
import AddEditDeliveryDialog from 'modules/deliveries/add-edit-delivery-dialog/add-edit-delivery-dialog';
import DeleteDeliveryDialog from 'modules/deliveries/delete-delivery-dialog/delete-delivery-dialog';

interface IDeliveryOverviewDetails {
  delivery: IDelivery;
}

const DeliveryOverviewDetails = ({ delivery }: IDeliveryOverviewDetails) => {
  const dialog = useDialog();
  const DeliveryDetails = [
    {
      label: 'Customer name',
      value: `${delivery.firstName} ${delivery.lastName}`,
      key: 'name',
    },
    {
      label: 'Address',
      value: createAddressString([...delivery.address.formatted_address, delivery.address.postcode]),
      key: 'address',
    },
    {
      label: 'Hospital number',
      value: isNotNullOrEmpty(delivery.hospitalNumber) ? delivery.hospitalNumber : '--',
      key: 'hospitalNumber',
    },
    {
      label: 'Number of parcels',
      value: delivery.parcelCount,
      key: 'parcelCount',
    },
    {
      label: 'Sharps bin required',
      value: getSharpsBinOptionNameFromKey(delivery.sharpsBin),
      key: 'sharpsBin',
    },
    {
      label: 'Waste collection required',
      value: getWasteCollectionNameFromKey(delivery.wasteCollection),
      key: 'wasteCollection',
    },
    {
      label: 'Phone number',
      value: delivery.phoneNumber,
      key: 'phoneNumber',
    },
    {
      label: 'Storage requirement',
      value: getStorageRequirementNameFromKey(delivery.storageRequirement),
      key: 'storageRequirement',
    },
    {
      label: 'Delivery type',
      value: getDeliveryTypeNameFromKey(delivery.type),
      key: 'type',
    },
    {
      label: 'Route',
      value: getDeliveryRouteAssignmentNameFromKey(delivery.route),
      key: 'route',
    },
    {
      label: 'Scheduled delivery date',
      value: dayjs(delivery.date).format('DD/MM/YYYY'),
      key: 'date',
    },
    {
      label: 'Time window',
      value: getTimeWindowNameFromKey(delivery.timeWindow ?? TimeWindow.ANY),
      key: 'timeWindow',
    },
    {
      label: 'Status',
      value: getDeliveryStatusNameFromKey(delivery.status),
      key: 'status',
    },
    ...(delivery.status === DeliveryStatus.DELIVERED
      ? [
          {
            label: 'Delivered at',
            value: dayjs(delivery.deliveredAt?.toDate()).format('HH:mm, DD/MM/YYYY'),
            key: 'deliveredAt',
          },
          {
            label: 'Delivered by',
            value: delivery.deliveredBy ? delivery.deliveredBy.fullName : 'Unknown',
            key: 'deliveredBy',
          },
        ]
      : []),
    {
      label: 'Created at',
      value: delivery.createdAt ? dayjs(delivery.createdAt.toDate()).format('HH:mm, DD/MM/YYYY') : 'Unknown',
      key: 'createdAt',
    },
    {
      label: 'Created by',
      value: delivery.createdBy ?? 'Unknown',
      key: 'createdBy',
    },
  ];

  return (
    <div className='flex-grow-0 flex-shrink-0 basis-[360px] bg-white shadow-md rounded-md'>
      <div className='border-b p-4 flex justify-between items-center'>
        <p className='headline-06'>Details</p>
        <div className='flex space-x-5'>
          <p
            className='body-02 text-red-500 hover:underline cursor-pointer'
            onClick={() => {
              dialog?.openDialog(<DeleteDeliveryDialog deliveries={[delivery.uid]} navigateTo='/deliveries' />);
            }}
          >
            Delete
          </p>
          {delivery.date >= getToday() && delivery.status === DeliveryStatus.SCHEDULED && (
            <p
              className='body-02 text-blue-700 hover:underline cursor-pointer'
              onClick={() => {
                dialog?.openDialog(<AddEditDeliveryDialog uid={delivery.uid} />);
              }}
            >
              Edit
            </p>
          )}
        </div>
      </div>
      <div className='p-4'>
        {DeliveryDetails.map((section) => (
          <div key={section.key} className='mt-4 first:mt-0'>
            <p className='body-02 text-gray-500'>{section.label}</p>
            <p className='body-01 break-words'>{section.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryOverviewDetails;
