import { storage } from 'core/config/firebase';
import { ref } from 'firebase/storage';
import ApiService from '../api.service';

const getAndroidAppDownloadUrl = () => {
  const imageRef = ref(storage, `appDownloads/android/app.apk`);
  return ApiService.getFileDownloadUrl(imageRef);
};

const AppDownloadsApiService = {
  getAndroidAppDownloadUrl,
};

export default AppDownloadsApiService;
