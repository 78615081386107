import ClassStringBuilder from 'core/utilities/class-string-builder';
import { Link, useLocation } from 'react-router-dom';
import { ISidebarItem } from './sidebar-item-interface';
import Badge from '@atlaskit/badge';

const SharedSidebarItem = ({ icon, label, route, badgeCount }: ISidebarItem) => {
  const location = useLocation();
  const active = location.pathname.startsWith(`/${route}`);
  const indicatorClasses = ['w-1', active ? 'bg-tertiary' : 'bg-white'];

  const backgroundClasses = ['border-b', active && 'bg-gray-100', 'hover:bg-gray-100'];
  return (
    <div className={ClassStringBuilder(backgroundClasses)}>
      <Link to={route}>
        <div className='flex w-full'>
          <div className={ClassStringBuilder(indicatorClasses)} />
          <div className='flex items-center py-5 px-6 space-x-4'>
            {icon}
            <p className='label-01'>{label}</p>
            {badgeCount && badgeCount > 0 && <Badge appearance='important'>{badgeCount}</Badge>}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SharedSidebarItem;
