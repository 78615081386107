import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { collection, doc, query } from 'firebase/firestore';
import { IGetData, IListAllData } from '../api-interface';
import ApiService from '../api.service';
import { IAddUserRequestDto, IUpdateUserRequestDto, IUser } from './users-api-interface';

const listAll = (): Promise<IListAllData<IUser>> => {
  const q = query(collection(firestore, FirestoreCollectionIDs.USERS));
  return ApiService.listAllData<IUser>(q);
};

const add = async (userDto: IAddUserRequestDto): Promise<string> => {
  const response = await ApiService.callFunction<string>(userDto, FirebaseFunctionNames.ADD_USER);
  return response;
};

const get = async (uid: string): Promise<IGetData<IUser>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.USERS, uid);
  return ApiService.getData(docRef);
};

const update = async (uid: string, userDoc: IUpdateUserRequestDto) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.USERS, uid);
  return ApiService.update(docRef, userDoc);
};

const remove = async (uid: string) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.USERS, uid);
  return ApiService.remove(docRef);
};

const subscribeToUser = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.USERS, uid);
  return ApiService.subscribeToDoc(docRef, subscriptionHandler, errorHandler);
};

const subscribeToAllUsers = (subscriptionHandler: Function, errorHandler: Function) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.USERS);
  return ApiService.subscribeToCollection(collectionRef, subscriptionHandler, errorHandler);
};

const UsersApiService = {
  listAll,
  add,
  get,
  update,
  remove,
  subscribeToUser,
  subscribeToAllUsers,
};

export default UsersApiService;
