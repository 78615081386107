import { Route, Routes } from 'react-router-dom';
import UsersList from './users-list/users-list';

const UsersRouting = () => {
  return (
    <Routes>
      <Route path='' element={<UsersList />} />
    </Routes>
  );
};

export default UsersRouting;
