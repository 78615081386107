export const DeliveryType = {
  OUTPATIENT: 'outpatient',
  HOMECARE: 'homecare',
};

export const DeliveryTypes: IDeliveryTypes[] = [
  {
    label: 'Outpatient',
    key: DeliveryType.OUTPATIENT,
  },
  {
    label: 'Homecare',
    key: DeliveryType.HOMECARE,
  },
];

export const getDeliveryTypeFromKey = (key: string) => {
  const type = DeliveryTypes.find((type) => type.key === key);
  return type;
};

export const getDeliveryTypeNameFromKey = (key: string) => {
  const type = DeliveryTypes.find((type) => type.key === key);
  return type?.label ?? 'Unknown';
};

export interface IDeliveryTypes {
  label: string;
  key: string;
}
