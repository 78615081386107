import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { collection, doc, orderBy, query } from 'firebase/firestore';
import ApiService from '../api.service';
import { IUpdateDriverMessageDto } from './driver-messages-api-interface';

const subscribe = (subscriptionHandler: Function, errorHandler: Function) => {
  const ref = collection(firestore, FirestoreCollectionIDs.DRIVER_MESSAGES);
  const q = query(ref, orderBy('sentAt', 'desc'));
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const update = async (uid: string, dto: IUpdateDriverMessageDto) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DRIVER_MESSAGES, uid);
  return ApiService.update(docRef, dto);
};

const DriverMessagesApiService = {
  subscribe,
  update,
};

export default DriverMessagesApiService;
