import { useFlags } from '@atlaskit/flag';
import { IUpdateDeliveryRequestDto } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { SuccessfulDeliveryFormFields } from 'core/config/form-fields';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';

interface ISuccessfulDeliveryDialog {
  deliveryUid: string;
  signatureDataUrl: string;
}

interface ISuccessfulDeliveryFormOutput {
  note?: string;
}

const SuccessfulDeliveryDialog = ({ deliveryUid, signatureDataUrl }: ISuccessfulDeliveryDialog) => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const navigate = useNavigate();
  const { userData } = useAuthState();

  // Page specifics
  const updateDelivery = async (data: ISuccessfulDeliveryFormOutput) => {
    setFormSubmitting(true);
    await DeliveriesApiService.uploadDeliverySignature(deliveryUid, signatureDataUrl);
    const payload: IUpdateDeliveryRequestDto = {
      driverNote: data.note,
      status: DeliveryStatus.DELIVERED,
      deliveredAt: new Date(),
      proofMethod: 'signature',
      deliveredBy: { fullName: userData?.fullName!, uid: userData?.uid! },
    };
    try {
      await DeliveriesApiService.update(deliveryUid, payload);
      dialog?.closeDialog();
      navigate('/driver');
      showSuccessFlag('Delivery updated', 'The delivery was successfully updated', flags);
    } catch (error) {
      showErrorFlag('Delivery update failed', `Unable to update this delivery, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4 overflow-y-auto'
        onSubmit={updateDelivery}
        fields={SuccessfulDeliveryFormFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
      />
    );
  };

  return (
    <SharedDialogBase title='Do you want to add a note?' customContentTemplate={customContent()} showButtons={false} />
  );
};

export default SuccessfulDeliveryDialog;
