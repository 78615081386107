import { DatePicker } from '@atlaskit/datetime-picker';
import { useFlags } from '@atlaskit/flag';
import { IRouteLog } from 'core/api/routes/routes-api-interface';
import RoutesApiService from 'core/api/routes/routes-api.service';
import { getRouteOptionNameFromKey } from 'core/constants/route-options';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { StringIndexable } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';

const RouteLogsDialog = () => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState<IRouteLog[]>([]);
  const [filters, setFilters] = useState<StringIndexable>({});
  const flags = useFlags();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IRouteLog>) => {
      setLogs(querySnapshot.docs.map((doc) => doc.data()));
      setLoading(false);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Route logs could not be retrieved, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = RoutesApiService.subscribeToRouteLogs(handleSnapshot, handleSubscriptionError, filters);
    return () => {
      unsubscribe();
    };
  }, [filters, flags]);

  const filterChanged = (key: string, value?: string) => {
    setFilters((prevState) => ({ ...prevState, [key]: value ? dayjs(value).toDate() : value }));
  };

  const customContent = () => {
    return loading ? (
      <p className='p-4'>Loading...</p>
    ) : (
      <>
        <div className='grid grid-cols-2 gap-4 p-4 border-b'>
          <div>
            <p className='label-02 font-semibold mb-1 text-gray-500'>Date from</p>
            <DatePicker
              selectProps={{ isSearchable: false }}
              locale={'en-UK'}
              defaultValue={filters.afterDate ?? ''}
              onChange={(value) => filterChanged('afterDate', value)}
            />
          </div>
          <div>
            <p className='label-02 font-semibold mb-1 text-gray-500'>Date to</p>
            <DatePicker
              selectProps={{ isSearchable: false }}
              locale={'en-UK'}
              defaultValue={filters.beforeDate ?? ''}
              onChange={(value) => filterChanged('beforeDate', value)}
            />
          </div>
        </div>
        <div className='overflow-y-auto'>
          {logs.map((log) => (
            <div className='p-4 border-b last:border-0'>
              <p className='text-xs'>{dayjs(log.createdAt.toDate()).format('DD/MM/YYYY, HH:mm')}</p>
              <div className='mt-2'>
                <p className='text-xs'>
                  {`Route date: ${dayjs(log.date).format('DD/MM/YYYY')}`}
                  <br />
                  {`Included routes: ${log.includedRoutes
                    ?.map((route) => getRouteOptionNameFromKey(route))
                    .join(', ')}`}
                  <br />
                  {`Delivery count: ${log.deliveryCount}`}
                  <br />
                  <span
                    className={log.unallocatedDeliveries.length > 0 ? 'text-red-500' : ''}
                  >{`Unallocated deliveries: ${log.unallocatedDeliveries.length}`}</span>
                </p>
                {log.unallocatedDeliveries.length > 0 && (
                  <div className='text-xs mt-2 border border-dashed'>
                    {log.unallocatedDeliveries.map((delivery) => (
                      <div className='flex justify-between items-center border-b border-dashed last:border-0 p-2'>
                        {delivery}
                        <SharedButton
                          onClick={() => window.open(`/deliveries/${delivery}`, '_blank')}
                          type='button'
                          appearance='link'
                          spacing='none'
                          label='View'
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {logs.length === 0 && <p className='body-02 p-4'>No results</p>}
        </div>
      </>
    );
  };

  return <SharedDialogBase title='Route generation logs' customContentTemplate={customContent()} />;
};

export default RouteLogsDialog;
