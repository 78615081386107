import { firestore, storage } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import { ref } from 'firebase/storage';
import { IGetData, IListAllData } from '../api-interface';

import ApiService from '../api.service';
import {
  IAddDeliveryRequestDto,
  IDelivery,
  IDeliveryQueryOptions,
  IUpdateDeliveryRequestDto,
} from './deliveries-api-interface';

export const deliveriesQueryList: StringIndexable = {
  lastName: { property: 'lastName', operator: '==' },
  status: { property: 'status', operator: '==' },
  date: { property: 'date', operator: '==' },
  hospitalNumber: { property: 'hospitalNumber', operator: '==' },
  type: { property: 'type', operator: '==' },
  postcode: { property: 'processedPostcode', operator: '==' },
  uids: { property: 'uid', operator: 'in' },
};

const add = async (deliveryDto: IAddDeliveryRequestDto) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERIES, deliveryDto.uid);
  return ApiService.set(docRef, deliveryDto);
};

const subscribeToAllDeliveries = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IDeliveryQueryOptions
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.DELIVERIES);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions)
      .filter((queryKey) => isNotNullOrEmpty(queryOptions[queryKey]))
      .map((queryKey: string) => {
        const { property, operator } = deliveriesQueryList[queryKey];
        return where(property, operator, queryOptions[queryKey]);
      });
  }

  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const listAll = async (
  last?: QueryDocumentSnapshot<IDelivery>,
  queryOptions?: IDeliveryQueryOptions
): Promise<IListAllData<IDelivery>> => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.DELIVERIES);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions)
      .filter((queryKey) => isNotNullOrEmpty(queryOptions[queryKey]))
      .map((queryKey: string) => {
        const { property, operator } = deliveriesQueryList[queryKey];
        return where(property, operator, queryOptions[queryKey]);
      });
  }

  if (last) {
    queryList.push(startAfter(last));
  }
  const queryLimit = 10;
  const q = query(
    collectionRef,
    orderBy('orderByDate', 'desc'),
    orderBy('address.town_or_city'),
    limit(queryLimit),
    ...queryList
  );
  return ApiService.listAllData<IDelivery>(q, queryLimit);
};

const get = async (uid: string): Promise<IGetData<IDelivery>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERIES, uid);
  return ApiService.getData(docRef);
};

const subscribeToDelivery = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERIES, uid);
  return ApiService.subscribeToDoc(docRef, subscriptionHandler, errorHandler);
};

const update = async (uid: string, deliveryDoc: IUpdateDeliveryRequestDto) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERIES, uid);
  return ApiService.update(docRef, deliveryDoc);
};

const uploadDeliveryImage = async (uid: string, dataUrl: string) => {
  const imageRef = ref(storage, `deliveryProof/${uid}.jpg`);
  return ApiService.uploadDataUrl(imageRef, dataUrl);
};

const uploadDeliverySignature = async (uid: string, dataUrl: string) => {
  const imageRef = ref(storage, `deliverySignature/${uid}.png`);
  return ApiService.uploadDataUrl(imageRef, dataUrl);
};

const getDeliveryImage = async (uid: string) => {
  const imageRef = ref(storage, `deliveryProof/${uid}.jpg`);
  return ApiService.getFileDownloadUrl(imageRef);
};

const getDeliverySignature = async (uid: string) => {
  const imageRef = ref(storage, `deliverySignature/${uid}.png`);
  return ApiService.getFileDownloadUrl(imageRef);
};

const remove = async (uid: string) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERIES, uid);
  return ApiService.remove(docRef);
};

const removeDeliveries = async (uids: string[]) => {
  const docRefs = uids.map((uid) => doc(firestore, FirestoreCollectionIDs.DELIVERIES, uid));
  return ApiService.batchRemove(docRefs);
};

const exportDeliveries = async (startDate: string, endDate: string): Promise<string> => {
  return ApiService.callFunction({ startDate, endDate }, FirebaseFunctionNames.EXPORT_DELIVERIES);
};

const exportSuccessfulDeliveries = async (startDate: string, endDate: string): Promise<string> => {
  return ApiService.callFunction({ startDate, endDate }, FirebaseFunctionNames.EXPORT_SUCCESSFUL_DELIVERIES, {
    timeout: 540000,
  });
};

const DeliveriesApiService = {
  add,
  subscribeToAllDeliveries,
  listAll,
  get,
  subscribeToDelivery,
  update,
  uploadDeliveryImage,
  getDeliveryImage,
  remove,
  removeDeliveries,
  exportDeliveries,
  uploadDeliverySignature,
  getDeliverySignature,
  exportSuccessfulDeliveries,
};

export default DeliveriesApiService;
