import { useFlags } from '@atlaskit/flag';
import Form, { Field } from '@atlaskit/form';
import { IDelivery, IDeliveryQueryOptions, createRandomDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { DeliveryStatuses, getDeliveryStatusNameFromKey } from 'core/constants/delivery-status';
import { useDialog } from 'core/providers/DialogProvider';
import { PageElement, SelectOption } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { ChangeEvent, useEffect, useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import { ILoadingButton } from 'shared/components/buttons/loading-button-interface';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import {
  ISharedTableColumn,
  ISharedTableCustomCellTemplate,
  ISharedTableRow,
} from 'shared/components/table/table-interface';
import { IDeliveriesListRow, IDeliverListFiltersOutput } from './deliveries-list-interface';
import TextField from '@atlaskit/textfield';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Filter } from 'react-feather';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { ValueType } from '@atlaskit/select';
import Select from '@atlaskit/select';
import { DeliveryTypes, getDeliveryTypeNameFromKey } from 'core/constants/delivery-types';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { ControlType } from 'core/enums/control-type';
import { ButtonGroup } from '@atlaskit/button';
import { useNavigate } from 'react-router-dom';
import DeleteDeliveryDialog from '../delete-delivery-dialog/delete-delivery-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import { useAuthState } from 'core/providers/AuthProvider';
import ExportDeliveriesDialog from '../export-deliveries-dialog/export-deliveries-dialog';
import AddEditDeliveryDialog from '../add-edit-delivery-dialog/add-edit-delivery-dialog';
import { getToday } from 'core/utilities/date-helpers';
import { getWasteCollectionNameFromKey } from 'core/constants/waste-collection';
import { getStorageRequirementNameFromKey } from 'core/constants/storage-requirement';
import { getSharpsBinOptionNameFromKey } from 'core/constants/sharps-bin-options';
import { useDeliveriesState } from 'core/providers/DeliveriesProvider';
import {
  DeliveryRouteAssignment,
  getDeliveryRouteAssignmentNameFromKey,
} from 'core/constants/delivery-route-assignment';
import { getRouteOptionNameFromKey } from 'core/constants/route-options';

const DeliveryTableColumns: ISharedTableColumn[] = [
  { label: '', key: 'select', templateId: 'select', width: 1, isCompact: true },
  { label: 'Customer Name', key: 'customerName', templateId: 'text', width: 10 },
  { label: 'Postcode', key: 'postcode', templateId: 'text', width: 10 },
  { label: 'Parcels', key: 'parcelCount', templateId: 'text', width: 5, isCompact: true },
  { label: 'Sharps bin', key: 'sharpsBin', templateId: 'text', width: 10 },
  { label: 'Waste collection', key: 'wasteCollection', templateId: 'text', width: 5 },
  { label: 'Storage', key: 'storageRequirement', templateId: 'text', width: 10 },
  { label: 'Delivery Date', key: 'date', templateId: 'text', width: 10 },
  { label: 'Status', key: 'status', templateId: 'text', width: 10 },
  { label: 'Type', key: 'type', templateId: 'text', width: 10 },
  { label: 'Route pot', key: 'route', templateId: 'text', width: 10 },
  { label: 'Allocated route', key: 'assignedRoute', templateId: 'text', width: 10 },
  { label: '', key: 'actions', templateId: 'actions', width: 9, isCompact: true },
];

const deliveryTypesOptions = DeliveryTypes.map((type) => ({ value: type.key, label: type.label }));

const deliveryStatusOptions = DeliveryStatuses.map((status) => ({ value: status.key, label: status.label }));

const filterOptions: ISharedFormField[] = [
  {
    label: 'Customer last name',
    key: 'lastName',
    control: ControlType.TextField,
    required: false,
  },
  {
    label: 'Delivery status',
    key: 'status',
    control: ControlType.Select,
    options: deliveryStatusOptions,
    required: false,
  },
  {
    label: 'Delivery date',
    key: 'date',
    control: ControlType.DatePicker,
    required: false,
  },
  {
    label: 'Delivery type',
    key: 'type',
    control: ControlType.Select,
    options: deliveryTypesOptions,
    required: false,
  },
  {
    label: 'Delivery postcode',
    key: 'postcode',
    control: ControlType.TextField,
    required: false,
  },
  {
    label: 'Hospital number',
    key: 'hospitalNumber',
    control: ControlType.TextField,
    required: false,
  },
];

const DeliveriesList = () => {
  // Local State
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState<ISharedTableRow<IDeliveriesListRow>[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<IDelivery>>();
  const [loadingMore, setLoadingMore] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterToShow, setFilterToShow] = useState<ISharedFormField>();
  const [filterQuery, setFilterQuery] = useState<IDeliveryQueryOptions>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const navigate = useNavigate();
  const { userRole } = useAuthState();
  const { todaysDeliveryCount, tomorrowsDeliveryCount } = useDeliveriesState();

  useEffect(() => {
    refreshDeliveries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const getAllocatedRoute = (key?: string) => {
    if (key === DeliveryRouteAssignment.NONE) {
      return 'Unallocated';
    }

    return key ? getRouteOptionNameFromKey(key) : 'Unknown';
  };

  const getDeliveries = async (last?: QueryDocumentSnapshot<IDelivery>, queryOptions?: IDeliveryQueryOptions) => {
    const result = await DeliveriesApiService.listAll(last, queryOptions);
    setLastVisible(result.lastVisible);
    return result.data.map((data) => ({
      key: data.uid,
      data: {
        ...data,
        customerName: `${data.firstName} ${data.lastName}`,
        date: dayjs(data.date).format('DD/MM/YYYY'),
        type: getDeliveryTypeNameFromKey(data.type),
        postcode: data.address.postcode,
        status: getDeliveryStatusNameFromKey(data.status),
        wasteCollection: getWasteCollectionNameFromKey(data.wasteCollection),
        storageRequirement: getStorageRequirementNameFromKey(data.storageRequirement),
        sharpsBin: getSharpsBinOptionNameFromKey(data.sharpsBin),
        route: getDeliveryRouteAssignmentNameFromKey(data.route),
        assignedRoute: getAllocatedRoute(data.assignedRoute),
      },
    }));
  };
  const showFetchError = () => {
    showErrorFlag('An error occurred', 'The deliveries could not be retrieved, please try again.', flags);
  };

  const refreshDeliveries = async () => {
    setLoading(true);
    setSelectedRows([]);
    try {
      const deliveryRows = await getDeliveries(undefined, filterQuery);
      setTableRows(deliveryRows);
      setLoading(false);
    } catch (error) {
      showFetchError();
    }
  };

  const loadMoreDeliveries = async () => {
    setLoadingMore(true);
    try {
      const deliveryRows = await getDeliveries(lastVisible, filterQuery);
      setTableRows((prevState) => [...prevState, ...deliveryRows]);
      setLoadingMore(false);
    } catch (error) {
      showFetchError();
    }
  };

  const loadMoreButton: ILoadingButton = {
    onClick: () => loadMoreDeliveries(),
    type: 'submit',
    appearance: 'subtle',
    label: 'Load more',
    isLoading: loadingMore,
  };

  const seedDeliveries = async () => {
    try {
      const count = Array.from(Array(15).keys());
      const deliveries = count.map((count) => createRandomDelivery());
      const promises = deliveries.map((del) => DeliveriesApiService.add(del));
      await Promise.all(promises);
      showSuccessFlag('Success', 'The deliveries were seeded.', flags);
    } catch (error) {
      console.log(error);
      showErrorFlag('An error occurred', 'The deliveries could not be created, please try again.', flags);
    }
  };

  const headerActions: PageElement[] = [
    ...(window.location.hostname === 'localhost'
      ? [
          {
            key: 'seed',
            element: (
              <SharedButton
                onClick={() => seedDeliveries()}
                type='button'
                appearance='default'
                label='Seed deliveries'
              />
            ),
          },
        ]
      : []),
    ...(userRole === 'admin'
      ? [
          {
            key: 'exportDeliveries',
            element: (
              <SharedButton
                onClick={() => dialog?.openDialog(<ExportDeliveriesDialog />)}
                type='button'
                appearance='default'
                label='Export deliveries'
              />
            ),
          },
        ]
      : []),
    ...(selectedRows.length
      ? [
          {
            key: 'deleteDeliveries',
            element: (
              <SharedButton
                onClick={() =>
                  dialog?.openDialog(<DeleteDeliveryDialog refreshPage={refreshDeliveries} deliveries={selectedRows} />)
                }
                type='button'
                appearance='danger'
                label='Delete deliveries'
              />
            ),
          },
        ]
      : [
          {
            key: 'addDelivery',
            element: (
              <SharedButton
                onClick={() => dialog?.openDialog(<AddEditDeliveryDialog refreshPage={refreshDeliveries} />)}
                type='button'
                appearance='primary'
                label='Add delivery'
              />
            ),
          },
        ]),
  ];

  const rowChecked = (event: ChangeEvent<HTMLInputElement>, uid: string) => {
    if (event.target.checked) {
      setSelectedRows((prevState) => [...prevState, uid]);
    } else {
      setSelectedRows((prevState) => prevState.filter((selectedUid) => selectedUid !== uid));
    }
  };

  const selectCellTemplate = (delivery: IDelivery) => {
    return <Checkbox isChecked={selectedRows.includes(delivery.uid)} onChange={(e) => rowChecked(e, delivery.uid)} />;
  };

  const actionCellTemplate = (delivery: IDelivery) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton onClick={() => navigate(delivery.uid)} type='button' appearance='link' label='View' />
        <SharedButton
          onClick={() =>
            dialog?.openDialog(<DeleteDeliveryDialog refreshPage={refreshDeliveries} deliveries={[delivery.uid]} />)
          }
          type='button'
          appearance='link'
          label='Delete'
        />
      </div>
    );
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: actionCellTemplate,
      id: 'actions',
    },
    { template: selectCellTemplate, id: 'select' },
  ];

  const applyFilters = async (data: IDeliverListFiltersOutput) => {
    setLoading(true);
    setLastVisible(undefined);
    const queries = {
      ...data,
      type: data.type?.value,
      status: data.status?.value,
      postcode: data.postcode?.toLowerCase().replace(/\s/g, ''),
    };
    setFilterQuery(queries);
  };

  const getDefaultFilterValue = (key: string, control: ControlType) => {
    if (!filterQuery || !filterQuery[key]) {
      switch (control) {
        case ControlType.Select:
          return null;
        case ControlType.DatePicker:
          return getToday();
        default:
          return '';
      }
    }
    return control === ControlType.Select
      ? deliveryTypesOptions.find((type) => type.value === filterQuery[key])
      : filterQuery[key];
  };

  const getFilterFieldToShow = () => {
    if (filterToShow) {
      switch (filterToShow.control) {
        case ControlType.Select:
          return (
            <Field<ValueType<SelectOption>>
              key={filterToShow.key}
              name={filterToShow.key}
              defaultValue={getDefaultFilterValue(filterToShow.key, filterToShow.control)}
              label={filterToShow.label}
            >
              {({ fieldProps }) => <Select<SelectOption> {...fieldProps} options={filterToShow.options} />}
            </Field>
          );
        case ControlType.DatePicker:
          return (
            <Field
              key={filterToShow.key}
              name={filterToShow.key}
              label={filterToShow.label}
              defaultValue={getDefaultFilterValue(filterToShow.key, filterToShow.control)}
            >
              {({ fieldProps: { id, ...rest } }) => (
                <DatePicker selectProps={{ inputId: id }} {...rest} locale={'en-UK'} />
              )}
            </Field>
          );
        default:
          return (
            <Field
              key={filterToShow.key}
              name={filterToShow.key}
              defaultValue={getDefaultFilterValue(filterToShow.key, filterToShow.control)}
              label={filterToShow.label}
            >
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
          );
      }
    }
  };

  const selectFilterToShow = (selectedOption: SelectOption | null) => {
    const selectedFilter = filterOptions.find((filter) => filter.key === selectedOption?.value);
    setFilterToShow(selectedFilter);
  };

  const clearFilters = () => {
    setFilterToShow(undefined);
    setFilterQuery(undefined);
  };

  return (
    <>
      <PageHeader title='Deliveries' actions={headerActions} />
      <div className='flex py-4 border-b body-02'>
        <p>
          Deliveries today: <span className='font-semibold'>{todaysDeliveryCount}</span>
        </p>
        <p className='border-l ml-2 pl-2'>
          Deliveries tomorrow: <span className='font-semibold'>{tomorrowsDeliveryCount}</span>
        </p>
      </div>
      <Form onSubmit={(data) => applyFilters(data)}>
        {({ formProps }) => (
          <form {...formProps} className='bg-white rounded-md shadow-md my-4'>
            <div
              className='p-3 flex justify-between items-center cursor-pointer'
              onClick={() => setShowFilters(!showFilters)}
            >
              <div className='flex items-center'>
                <Filter />
                <p className='ml-2 body-02'>Filters</p>
              </div>
              <div>
                <SharedButton
                  onClick={() => setShowFilters(!showFilters)}
                  type='button'
                  appearance='default'
                  label={showFilters ? 'Hide filters' : 'Show filters'}
                />
              </div>
            </div>
            {showFilters && (
              <div className='px-3 pb-3 flex flex-col items-end border-t'>
                <div className='grid grid-cols-6 gap-3 mb-3 w-full'>
                  <Field<ValueType<SelectOption>> name='type' defaultValue={null} label='Selected filter'>
                    {({ fieldProps }) => (
                      <Select<SelectOption>
                        {...fieldProps}
                        value={filterToShow ? { value: filterToShow.key, label: filterToShow.label! } : null}
                        onChange={(value) => selectFilterToShow(value)}
                        options={filterOptions.map((option) => ({ value: option.key, label: option.label! }))}
                      />
                    )}
                  </Field>
                  {filterToShow && getFilterFieldToShow()}
                </div>
                <ButtonGroup>
                  <SharedButton onClick={() => clearFilters()} type='button' appearance='subtle' label='Clear' />
                  <SharedLoadingButton
                    type='submit'
                    appearance='primary'
                    isLoading={loading}
                    label='Apply'
                    disabled={filterToShow === undefined}
                  />
                </ButtonGroup>
              </div>
            )}
          </form>
        )}
      </Form>
      <SharedTable
        columns={DeliveryTableColumns}
        rows={tableRows}
        isLoading={loading}
        loadMoreButton={lastVisible && loadMoreButton}
        customTemplates={customTemplates}
      />
    </>
  );
};

export default DeliveriesList;
