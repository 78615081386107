export const StorageRequirement = {
  FRIDGE: 'fridge',
  AMBIENT: 'ambient',
  BOTH: 'both',
};

export const StorageRequirements: IStorageRequirement[] = [
  {
    label: 'Fridge',
    key: StorageRequirement.FRIDGE,
  },
  {
    label: 'Ambient',
    key: StorageRequirement.AMBIENT,
  },
  {
    label: 'Fridge & Ambient',
    key: StorageRequirement.BOTH,
  },
];

export const getStorageRequirementFromKey = (key: string) => {
  const sr = StorageRequirements.find((sr) => sr.key === key);
  return sr;
};

export const getStorageRequirementNameFromKey = (key: string) => {
  const sr = StorageRequirements.find((sr) => sr.key === key);
  return sr?.label ?? 'Unknown';
};

export interface IStorageRequirement {
  label: string;
  key: string;
}
