import Spinner from '@atlaskit/spinner';
import { useAuthState } from 'core/providers/AuthProvider';
import DeliveriesRouting from 'modules/deliveries/deliveries-routing';
import DriverRouting from 'modules/driver/driver-routing';
import LoginRouting from 'modules/login/login-routing';
import ReportingRouting from 'modules/dashboard/dashboard-routing';
import RoutesRouting from 'modules/routes/routes-routing';
import UsersRouting from 'modules/users/users-routing';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedRoute from './authenticated-route';
import PublicRoute from './public-route';
import RoleHandler from './role-handler';
import ForgotPasswordRouting from 'modules/forgot-password/forgot-password-routing';
import { useLoadScript } from '@react-google-maps/api';
import SettingsRouting from 'modules/settings/settings-routing';
import MessagesRouting from 'modules/driver-messages/driver-messages-routing';
import { DriverMessagesProvider } from 'core/providers/DriverMessagesProvider';

const AppRouting = () => {
  const { authChecked, userRole } = useAuthState();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });
  const getDefaultPath = () => {
    switch (userRole) {
      case 'driver':
        return 'driver';
      default:
        return 'deliveries';
    }
  };

  if (!authChecked || !isLoaded) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Spinner size={60} />
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path='login/*' element={<LoginRouting />} />
        <Route path='forgot-password/*' element={<ForgotPasswordRouting />} />
      </Route>

      <Route element={<AuthenticatedRoute />}>
        <Route element={<DriverMessagesProvider />}>
          <Route element={<RoleHandler roles={['admin']} />}>
            <Route path='users/*' element={<UsersRouting />} />
            <Route path='settings/*' element={<SettingsRouting />} />
          </Route>

          <Route element={<RoleHandler roles={['admin', 'homecareDispenser']} />}>
            <Route path='deliveries/*' element={<DeliveriesRouting />} />
            <Route path='routes/*' element={<RoutesRouting />} />
            <Route path='driver-messages/*' element={<MessagesRouting />} />
            <Route path='dashboard/*' element={<ReportingRouting />} />
          </Route>

          <Route element={<RoleHandler roles={['driver']} />}>
            <Route path='driver/*' element={<DriverRouting />} />
          </Route>
        </Route>
      </Route>

      <Route path='' element={<Navigate replace to={getDefaultPath()} />} />
      <Route path='*' element={<Navigate replace to={getDefaultPath()} />} />
    </Routes>
  );
};

export default AppRouting;
