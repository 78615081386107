import { useState } from 'react';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import SharedMobileHeader from 'shared/components/layout/mobile-header';
import Android from 'assets/images/android.png';
import AppDownloadsApiService from 'core/api/app-downloads/app-downloads-api-service';

const AppDownload = () => {
  const [androidDownloading, setAndroidDownloading] = useState(false);

  const downloadAndroid = async () => {
    setAndroidDownloading(true);
    const downloadUrl = await AppDownloadsApiService.getAndroidAppDownloadUrl();
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = `dhive-driver-android.apk`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setAndroidDownloading(false);
  };

  return (
    <div className='bg-primary'>
      <SharedMobileHeader backButton />
      <div className='bg-white rounded-t-2xl'>
        <div className='px-4 py-6 border-b'>
          <p className='headline-04'>App download</p>
        </div>
        <div className='w-full border-b p-4 flex justify-between items-center'>
          <div className='flex space-x-4'>
            <img src={Android} alt='android' className='h-6' />
            <p className='font-semibold'>Android</p>
          </div>
          <SharedLoadingButton
            type='button'
            appearance='link'
            label='Download'
            spacing='none'
            isLoading={androidDownloading}
            onClick={downloadAndroid}
          />
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
