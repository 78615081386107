import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { collection, doc, limit, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { IGetData } from '../api-interface';
import ApiService from '../api.service';
import { IDeliveryRouteQueryOptions, IRoute, IRouteLogsQueryOptions } from './routes-api-interface';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';

const recalculateRoute = async (date: string, routeAssignment: string) => {
  const response = await ApiService.callFunction<string>(
    { date, routeAssignment },
    FirebaseFunctionNames.RECALCULATE_ROUTE
  );
  return response;
};

export const routesQueryList: StringIndexable = {
  date: { property: 'date', operator: '==' },
  routeKey: { property: 'routeKey', operator: '==' },
};

const getDeliveryRoutes = (queryOptions?: IDeliveryRouteQueryOptions, ordering?: string[], queryLimit?: number) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.DELIVERY_ROUTES);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = routesQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }

  if (queryLimit) {
    queryList.push(limit(queryLimit));
  }
  const q = query(collectionRef, ...queryList);
  return ApiService.listAllData<IRoute>(q, queryLimit);
};

const subscribeToRoutes = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IDeliveryRouteQueryOptions,
  ordering?: string[],
  queryLimit?: number
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.DELIVERY_ROUTES);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = routesQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }

  if (ordering) {
    ordering.forEach((order) => queryList.push(orderBy(order, 'desc')));
  }

  if (queryLimit) {
    queryList.push(limit(queryLimit));
  }

  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const get = async (uid: string): Promise<IGetData<IRoute>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.DELIVERY_ROUTES, uid);
  return ApiService.getData(docRef);
};

const subscribeToRoute = (uid: string, subscriptionHandler: Function, errorHandler: Function, collectionId: string) => {
  const docRef = doc(firestore, collectionId, uid);
  return ApiService.subscribeToDoc(docRef, subscriptionHandler, errorHandler);
};

const routeLogsQueryList: StringIndexable = {
  afterDate: { property: 'createdAt', operator: '>' },
  beforeDate: { property: 'createdAt', operator: '<' },
};

const subscribeToRouteLogs = (
  subscriptionHandler: Function,
  errorHandler: Function,
  queryOptions?: IRouteLogsQueryOptions
) => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.ROUTE_LOGS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions)
      .filter((queryKey) => isNotNullOrEmpty(queryOptions[queryKey]))
      .map((queryKey: string) => {
        const { property, operator } = routeLogsQueryList[queryKey];
        return where(property, operator, queryOptions[queryKey]);
      });
  }

  queryList.push(limit(50), orderBy('createdAt', 'desc'));

  const q = query(collectionRef, ...queryList);
  return ApiService.subscribeToCollection(q, subscriptionHandler, errorHandler);
};

const RoutesApiService = {
  recalculateRoute,
  subscribeToRoutes,
  subscribeToRoute,
  get,
  getDeliveryRoutes,
  subscribeToRouteLogs,
};

export default RoutesApiService;
