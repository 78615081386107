import { firestore } from 'core/config/firebase';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { doc } from 'firebase/firestore';
import { IGetData } from '../api-interface';
import ApiService from '../api.service';
import { IUpdateDeliveryMeta } from './meta-api-interface';

const get = async <TMeta>(uid: string): Promise<IGetData<TMeta>> => {
  const docRef = doc(firestore, FirestoreCollectionIDs.META, uid);
  return ApiService.getData(docRef);
};

const update = async (uid: string, metaDoc: IUpdateDeliveryMeta) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.META, uid);
  return ApiService.update(docRef, metaDoc);
};

const subscribeToMeta = (uid: string, subscriptionHandler: Function, errorHandler: Function) => {
  const docRef = doc(firestore, FirestoreCollectionIDs.META, uid);
  return ApiService.subscribeToDoc(docRef, subscriptionHandler, errorHandler);
};

const MetaApiService = {
  get,
  update,
  subscribeToMeta,
};

export default MetaApiService;
