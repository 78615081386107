import { firestore } from 'core/config/firebase';
import { FirebaseFunctionNames } from 'core/constants/firebase-function-names';
import { FirestoreCollectionIDs } from 'core/constants/firestore-collection-ids';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { collection, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { IListAllData } from '../api-interface';
import ApiService from '../api.service';
import { IReport, IReportQueryOptions } from './reports-api-interface';

export const reportsQueryList: StringIndexable = {
  startDate: { property: 'date', operator: '>=' },
};

const listAll = async (queryOptions?: IReportQueryOptions): Promise<IListAllData<IReport>> => {
  const collectionRef = collection(firestore, FirestoreCollectionIDs.REPORTS);
  let queryList: QueryConstraint[] = [];
  if (queryOptions) {
    queryList = Object.keys(queryOptions).map((queryKey: string) => {
      const { property, operator } = reportsQueryList[queryKey];
      return where(property, operator, queryOptions[queryKey]);
    });
  }
  const q = query(collectionRef, orderBy('date'), ...queryList);
  return ApiService.listAllData(q);
};

const aggregateData = async (dates: string[]) => {
  const response = await ApiService.callFunction<string>({ dates }, FirebaseFunctionNames.AGGREGATE_DATA);
  return response;
};

const ReportsApiService = {
  listAll,
  aggregateData,
};

export default ReportsApiService;
