import { useFlags } from '@atlaskit/flag';
import { IRoute } from 'core/api/routes/routes-api-interface';
import RoutesApiService from 'core/api/routes/routes-api.service';
import { RouteOption, RouteOptions } from 'core/constants/route-options';
import { getToday, getTomorrow } from 'core/utilities/date-helpers';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from 'shared/components/page-header/page-header';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
import { ISharedTableRow } from 'shared/components/table/table-interface';
import RouteTable from '../route-table/route-table';
import SharedButton from 'shared/components/buttons/button';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { DeliveryRouteAssignment } from 'core/constants/delivery-route-assignment';
import { PageElement } from 'core/utilities/interface-helpers';
import { useDialog } from 'core/providers/DialogProvider';
import RouteLogsDialog from '../route-logs-dialog/route-logs-dialog';

interface IRoutesOverviewSectionRoutes {
  [key: string]: ISharedTableRow<IRoute>[] | undefined;
}

interface ISectionState {
  [key: string]: boolean;
}

const RoutesOverview = () => {
  const [initializing, setInitializing] = useState(true);
  const [sectionRoutes, setSectionRoutes] = useState<IRoutesOverviewSectionRoutes>();
  const [loading, setLoading] = useState<ISectionState>({});
  const [getRoutes, setGetRoutes] = useState(true);
  const navigate = useNavigate();
  const flags = useFlags();
  const [showSection, setShowSection] = useState<ISectionState>({
    regular: true,
    hospital: true,
  });
  const dialog = useDialog();

  const recalculateRoute = useCallback(
    async (key: string) => {
      try {
        setLoading((prevState) => ({ ...prevState, [`${key}Today`]: true }));
        await RoutesApiService.recalculateRoute(getToday(), key);
        setGetRoutes(true);
        showSuccessFlag('Success', 'The route for today was successfully recalculated', flags);
      } catch (error) {
        showErrorFlag('Error', 'The route could not be calculated due to an error, please try again.', flags);
      }
      setLoading((prevState) => ({ ...prevState, [`${key}Today`]: false }));
    },
    [flags]
  );

  const mainSections = [
    {
      key: DeliveryRouteAssignment.REGULAR,
      title: 'Standard delivery routes',
      description: 'Contains all of the regular delivery routes',
      routes: RouteOptions.filter((option) => option.section === 'regular'),
    },
    {
      key: 'assigned',
      title: 'Assigned routes',
      description: 'Contains all of the delivery routes that are specifically assigned',
      routes: RouteOptions.filter((option) => option.section === 'assigned'),
    },
  ];

  const calculateTomorrow = useCallback(async () => {
    try {
      setLoading((prevState) => ({ ...prevState, [`${DeliveryRouteAssignment.REGULAR}Tomorrow`]: true }));
      await RoutesApiService.recalculateRoute(getTomorrow(), RouteOption.REGULAR);
      setGetRoutes(true);
      showSuccessFlag('Success', 'The route for tomorrow was successfully calculated', flags);
    } catch (error) {
      showErrorFlag('Error', 'The route could not be calculated due to an error, please try again.', flags);
    }
    setLoading((prevState) => ({ ...prevState, [`${DeliveryRouteAssignment.REGULAR}Tomorrow`]: false }));
  }, [flags]);

  const sections = useMemo(() => {
    return RouteOptions.map((option) => {
      const buttons = [];

      if (option.value === RouteOption.HOSPITAL) {
        buttons.push({
          onClick: () => recalculateRoute(option.value),
          label: 'Recalculate today',
          key: option.value,
        });
      }

      return {
        key: option.value,
        title: option.label,
        buttons,
      };
    });
  }, [recalculateRoute]);

  const getSectionRoutes = useCallback(async () => {
    const promises = sections.map((section) =>
      RoutesApiService.getDeliveryRoutes({ routeKey: section.key }, ['date'], 5)
    );
    const sectionRoutes = await Promise.all(promises);
    sectionRoutes.forEach((sR, index) => {
      const key = sections[index].key;
      const routes = sR.data.map((route) => ({
        key: route.uid,
        data: {
          ...route,
          date: dayjs(route.date).format('DD/MM/YYYY'),
        },
      }));
      setSectionRoutes((prevState) => ({ ...prevState, [key]: routes }));
    });
    setGetRoutes(false);
    setInitializing(false);
  }, [sections]);

  useEffect(() => {
    if (getRoutes) {
      getSectionRoutes();
    }
  }, [getRoutes, getSectionRoutes]);

  if (initializing || !sectionRoutes) {
    return <PageHeaderSkeleton actions={[]} subtitle={false} />;
  }

  const headerActions: PageElement[] = [
    {
      key: 'viewLogs',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<RouteLogsDialog />)}
          type='button'
          appearance='primary'
          label='View logs'
        />
      ),
    },
  ];

  const recalculateTodayButton = (key: string) => (
    <SharedLoadingButton
      isLoading={loading[`${key}Today`]}
      type='button'
      appearance='link'
      label='Recalculate today'
      onClick={() => recalculateRoute(key)}
    />
  );

  return (
    <>
      <PageHeader title='Routes' actions={headerActions} />
      {mainSections.map((section) => (
        <div key={section.key} className='my-4 py-4 shadow-md rounded-md bg-slate-100 p-4'>
          <div className='flex justify-between items-center'>
            <p className='headline-05'>{section.title}</p>
            <div className='space-x-4 text-sm'>
              {section.key === DeliveryRouteAssignment.REGULAR && (
                <SharedLoadingButton
                  isLoading={loading[`${section.key}Tomorrow`]}
                  type='button'
                  appearance='link'
                  label='Calculate tomorrow'
                  onClick={() => calculateTomorrow()}
                />
              )}
              {section.key !== 'assigned' && recalculateTodayButton(section.key)}
              <SharedButton
                onClick={() => {
                  setShowSection((prevState) => ({ ...prevState, [section.key]: !showSection[section.key] }));
                }}
                type='button'
                appearance='link'
                label={showSection[section.key] ? 'Hide' : 'Show'}
              />
            </div>
          </div>
          {showSection[section.key] && (
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 border-t pt-4 mt-4'>
              {section.routes.map((route) => (
                <div key={route.value}>
                  <div className='flex justify-between items-center py-4 border-b body-02 mb-4'>
                    <p>{route.label}</p>
                    {section.key === 'assigned' && recalculateTodayButton(route.value)}
                  </div>
                  <RouteTable
                    routeType={route.value}
                    tableRows={sectionRoutes[route.value] ?? []}
                    loadMoreButton={{
                      onClick: () => navigate(`/routes/view?key=${route.value}`),
                      type: 'submit',
                      appearance: 'subtle',
                      label: 'View all',
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default RoutesOverview;
