import { useAuthState } from 'core/providers/AuthProvider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PublicRoute = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthState();
  return !isAuthenticated ? <Outlet /> : <Navigate to='' state={{ from: location }} replace />;
};

export default PublicRoute;
