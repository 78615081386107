import { PageElement } from 'core/utilities/interface-helpers';
import SkeletonElement from '../layout/skeleton-element';

interface IPageHeaderSkeleton {
  actions: PageElement[];
  subtitle?: boolean;
}

const PageHeaderSkeleton = ({ actions, subtitle }: IPageHeaderSkeleton) => {
  return (
    <div className='min-h-[88px] flex items-center justify-between border-b'>
      <div className='py-6'>
        <SkeletonElement height='36px' width='350px' className='mb-2' />
        {subtitle && <SkeletonElement height='24px' width='180px' />}
      </div>
      <div>
        {actions.map((action) => (
          <SkeletonElement key={action.key} height='36px' width='120px' />
        ))}
      </div>
    </div>
  );
};

export default PageHeaderSkeleton;
