import { StringIndexable } from 'core/utilities/interface-helpers';
import { Timestamp } from 'firebase/firestore';
import { IAddress, createRandomAddress } from '../address/address-api-interface';
import { faker } from '@faker-js/faker/locale/en_GB';
import dayjs from 'dayjs';
import { DeliveryTypes } from 'core/constants/delivery-types';
import { WasteCollectionOptions } from 'core/constants/waste-collection';
import { StorageRequirements } from 'core/constants/storage-requirement';
import { SharpsBinOptions } from 'core/constants/sharps-bin-options';
import { DeliveryRouteAssignment } from 'core/constants/delivery-route-assignment';
import { TimeWindow } from 'core/constants/time-window';
interface IDeliveryUser {
  uid: string;
  fullName: string;
}
export interface IDelivery extends StringIndexable {
  uid: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  phoneNumber: string;
  parcelCount: string;
  hospitalNumber: string;
  date: string;
  orderByDate: string;
  notes: string;
  status: string;
  type: string;
  processedPostcode: string;
  driverNote: string;
  deliveredAt?: Timestamp;
  clinicCode: string;
  createdAt: Timestamp;
  rescheduled?: boolean;
  createdBy: string;
  wasteCollection: string;
  storageRequirement: string;
  sharpsBin: string;
  route: string;
  proofMethod?: string;
  assignedRoute?: string;
  timeWindow?: string;
  deliveredBy?: IDeliveryUser;
}

export interface IAddDeliveryRequestDto {
  uid: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  phoneNumber: string;
  parcelCount: string;
  hospitalNumber: string;
  date: string;
  orderByDate: string;
  notes: string;
  status: string;
  type: string;
  processedPostcode: string;
  clinicCode: string;
  createdAt?: Date;
  createdBy?: string;
  wasteCollection: string;
  storageRequirement: string;
  sharpsBin: string;
  route: string;
  assignedRoute: string;
  timeWindow: string;
}

export const createRandomDelivery = (): IAddDeliveryRequestDto => {
  const date = new Date();
  const dateString = dayjs(date).format('YYYY-MM-DD');
  const postcode = faker.address.zipCode();
  const processedPostcode = postcode.toLowerCase().replace(/\s/g, '');
  return {
    uid: faker.datatype.uuid(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    address: createRandomAddress(postcode),
    phoneNumber: faker.phone.number(),
    parcelCount: faker.datatype.number().toString(),
    hospitalNumber: faker.datatype.number().toString(),
    date: dateString,
    orderByDate: dateString,
    notes: '',
    status: 'scheduled',
    type: faker.helpers.arrayElement(DeliveryTypes.map((type) => type.key)),
    processedPostcode,
    clinicCode: faker.datatype.number().toString(),
    createdAt: date,
    createdBy: faker.name.firstName(),
    wasteCollection: faker.helpers.arrayElement(WasteCollectionOptions.map((type) => type.key)),
    storageRequirement: faker.helpers.arrayElement(StorageRequirements.map((type) => type.key)),
    sharpsBin: faker.helpers.arrayElement(SharpsBinOptions.map((type) => type.key)),
    route: DeliveryRouteAssignment.HOSPITAL,
    assignedRoute: DeliveryRouteAssignment.NONE,
    timeWindow: TimeWindow.ANY,
  };
};

export interface IUpdateDeliveryRequestDto {
  uid?: string;
  firstName?: string;
  lastName?: string;
  address?: IAddress;
  phoneNumber?: string;
  parcelCount?: string;
  hospitalNumber?: string;
  date?: string;
  orderByDate?: string;
  notes?: string;
  status?: string;
  type?: string;
  processedPostcode?: string;
  driverNote?: string;
  deliveredAt?: Date;
  rescheduled?: boolean;
  wasteCollection?: string;
  storageRequirement?: string;
  sharpsBin?: string;
  route?: string;
  proofMethod?: string;
  routeAssignment?: string;
  timeWindow?: string;
  deliveredBy?: IDeliveryUser;
}

export interface IDeliveryQueryOptions extends StringIndexable {
  lastName?: string;
  date?: string;
  hospitalNumber?: string;
  type?: string;
  processedPostcode?: string;
  uids?: string[];
}

export type DeliveryType = 'homecare' | 'outpatient';
