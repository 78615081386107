import { Route, Routes } from 'react-router-dom';
import SettingsOverview from './settings-overview/settings-overview';

const SettingsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<SettingsOverview />} />
    </Routes>
  );
};

export default SettingsRouting;
