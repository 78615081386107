import TextField from '@atlaskit/textfield';
import AddressApiService from 'core/api/address/address-api.service';
import { useState } from 'react';
import { IAddressSuggestion, IAddressSuggestions } from 'core/api/address/address-api-interface';
import { createAddressString } from 'core/utilities/address-string-builder';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag } from 'core/utilities/flags-helper';
import SharedLoadingButton from 'shared/components/buttons/loading-button';

interface IAddressSearch {
  fieldProps: any;
}

const AddressSearch = ({ fieldProps }: IAddressSearch) => {
  const defaultAddress = fieldProps.value.fullAddress;
  const [suggestions, setSuggestions] = useState<IAddressSuggestions>();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>(
    defaultAddress ? createAddressString([...defaultAddress.formatted_address, defaultAddress.postcode]) : ''
  );
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>(
    defaultAddress ? createAddressString([...defaultAddress.formatted_address, defaultAddress.postcode]) : undefined
  );

  const flags = useFlags();
  const handleSelect = async (suggestion: IAddressSuggestion) => {
    try {
      const fullAddress = await AddressApiService.getFullAddress(suggestion.id);
      setSelectedAddress(`${suggestion.address}, ${fullAddress.postcode}`);
      fieldProps.onChange({
        fullAddress: fullAddress,
        valid: true,
      });
      setSuggestions(undefined);
    } catch (error) {
      showErrorFlag(
        'An error occurred',
        'We failed to get the full details for this address, please try again.',
        flags
      );
      console.error(error);
    }
  };

  const getSuggestions = async () => {
    if (value === '') {
      return;
    }
    setLoading(true);
    try {
      const result = await AddressApiService.autocomplete(value);
      setSuggestions(result);
      setLoading(false);
    } catch (error) {
      showErrorFlag(
        'An error occurred',
        'We could not retrieve the address suggestions for your query, please try again.',
        flags
      );
      console.error(error);
    }
  };

  const inputChanged = (value: string) => {
    setValue(value);
    setSuggestions(undefined);
  };

  const resetSearch = () => {
    setSelectedAddress(undefined);
    fieldProps.onChange({
      valid: false,
    });
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        {!selectedAddress ? (
          <>
            <TextField {...fieldProps} value={value} onChange={(e) => inputChanged(e.currentTarget.value)} />
            <div className='ml-2'>
              <SharedLoadingButton
                isLoading={loading}
                onClick={() => getSuggestions()}
                type={undefined}
                appearance='primary'
                label='Search'
              />
            </div>
          </>
        ) : (
          <>
            <TextField isDisabled value={selectedAddress} onChange={() => {}} />
            <div className='ml-2'>
              <SharedLoadingButton
                isLoading={loading}
                onClick={() => resetSearch()}
                type={undefined}
                appearance='primary'
                label='Reset'
              />
            </div>
          </>
        )}
      </div>
      {suggestions && (
        <div className='border-2 rounded-sm overflow-hidden mt-2'>
          {loading ? (
            <div className='body-02 p-1.5'>Searching...</div>
          ) : (
            <div className='max-h-[200px] overflow-y-auto'>
              {suggestions.suggestions.map((suggestion: IAddressSuggestion) => (
                <div
                  key={suggestion.id}
                  className='cursor-pointer hover:bg-gray-50 body-02 p-1.5 border-t-2 first:border-t-0'
                  onClick={() => handleSelect(suggestion)}
                >
                  {suggestion.address}
                </div>
              ))}
              {suggestions.suggestions.length === 0 && <p className='body-02 p-1.5'>No results</p>}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AddressSearch;
