import { StringIndexable } from 'core/utilities/interface-helpers';
import SharedLoadingButton from '../buttons/loading-button';
import SharedTableBody from './table-body/table-body';
import SharedTableHead from './table-head/table-head';
import { ISharedTable, ISharedTableCustomCellTemplate } from './table-interface';

const SharedTable = <T extends StringIndexable>({
  columns,
  customTemplates = [],
  rows,
  isLoading = false,
  loadMoreButton,
  onRowClick,
}: ISharedTable<T>) => {
  const textCellTemplate = (data: string) => <p>{data}</p>;
  const templates: ISharedTableCustomCellTemplate[] = [
    {
      template: textCellTemplate,
      id: 'text',
    },
    ...customTemplates,
  ];
  return (
    <>
      <div className='bg-white w-full rounded-md shadow-md overflow-x-auto'>
        <table className={`w-full ${loadMoreButton && rows.length !== 0 ? 'border-b' : ''}`}>
          <SharedTableHead columns={columns} />
          <SharedTableBody
            rows={rows}
            templates={templates}
            columns={columns}
            isLoading={isLoading}
            onRowClick={onRowClick}
          />
        </table>
        {rows.length === 0 && !isLoading && (
          <div className={`border-t ${loadMoreButton ? 'border-b' : ''}`}>
            <p className='body-02 p-6 text-center'>No results</p>
          </div>
        )}
        {loadMoreButton && (
          <div className='body-02 p-3 flex justify-center w-full'>
            <SharedLoadingButton {...loadMoreButton} />
          </div>
        )}
      </div>
    </>
  );
};

export default SharedTable;
