import { useFlags } from '@atlaskit/flag';
import { IAddDeliveryRequestDto, IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { AddDeliveryFormFields } from 'core/config/form-fields';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { getDeliveryTypeNameFromKey } from 'core/constants/delivery-types';
import { getSharpsBinOptionNameFromKey } from 'core/constants/sharps-bin-options';
import { getStorageRequirementNameFromKey, StorageRequirement } from 'core/constants/storage-requirement';
import { getWasteCollectionNameFromKey } from 'core/constants/waste-collection';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useEffect, useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { v4 as uuid } from 'uuid';
import { IAddEditDeliveryDialog, IAddEditDeliveryFormOutput } from './add-edit-delivery-dialog-interface';
import * as turf from '@turf/turf';
import { RouteOption } from '../../../core/constants/route-options';
import {
  DeliveryRouteAssignment,
  getDeliveryRouteAssignmentNameFromKey,
} from 'core/constants/delivery-route-assignment';
import { TimeWindow, getTimeWindowNameFromKey } from 'core/constants/time-window';

const AddEditDeliveryDialog = ({ refreshPage, uid }: IAddEditDeliveryDialog) => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [formFields, setFormFields] = useState(AddDeliveryFormFields);
  const [delivery, setDelivery] = useState<IDelivery>();
  const [formError, setFormError] = useState<string>();
  const editing = uid !== undefined;

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const { userData } = useAuthState();

  const getDefaultValue = (delivery: IDelivery, key: string) => {
    switch (key) {
      case 'address':
        return {
          fullAddress: delivery.address,
          valid: true,
        };
      case 'type':
        return {
          value: delivery.type,
          label: getDeliveryTypeNameFromKey(delivery.type),
        };
      case 'wasteCollection':
        return {
          value: delivery.wasteCollection,
          label: getWasteCollectionNameFromKey(delivery.wasteCollection),
        };
      case 'storageRequirement':
        return {
          value: delivery.storageRequirement,
          label: getStorageRequirementNameFromKey(delivery.storageRequirement),
        };
      case 'sharpsBin':
        return {
          value: delivery.sharpsBin,
          label: getSharpsBinOptionNameFromKey(delivery.sharpsBin),
        };
      case 'route':
        return {
          value: delivery.route,
          label: getDeliveryRouteAssignmentNameFromKey(delivery.route),
        };
      case 'timeWindow':
        return {
          value: delivery.timeWindow,
          label: getTimeWindowNameFromKey(delivery.timeWindow ?? TimeWindow.ANY),
        };
      case 'clinicCode':
        return typeof delivery.clinicCode === 'object'
          ? delivery.clinicCode
          : {
              value: delivery.clinicCode,
              label: delivery.clinicCode,
            };
      default:
        return delivery[key];
    }
  };

  useEffect(() => {
    if (editing) {
      const prepare = async () => {
        setFetchingData(true);
        const deliverySnap = await DeliveriesApiService.get(uid);
        setDelivery(deliverySnap.data);
        const filledFormFields = AddDeliveryFormFields.map((field) => ({
          ...field,
          defaultValue: getDefaultValue(deliverySnap.data, field.key),
        }));
        setFormFields(filledFormFields);
        setFetchingData(false);
      };

      prepare();
    }
  }, [editing, uid]);

  // Page specifics
  const addDelivery = async (data: IAddEditDeliveryFormOutput) => {
    setFormError(undefined);
    const parcelCountInt = parseInt(data.parcelCount);
    if (parcelCountInt < 1) {
      setFormError('The number of parcels must be greater than 0');
      return;
    }

    if (parcelCountInt < 2 && data.storageRequirement.value === StorageRequirement.BOTH) {
      setFormError('Fridge & ambient storage can only be selected for deliveries containing more than 1 parcel');
      return;
    }

    const poly = turf.polygon([
      [
        [-1.914276967878095, 52.67081549230912],
        [-1.8239735489200655, 52.645161153883635],
        [-1.499338473174447, 52.878957964315134],
        [-1.5953572983953848, 52.92032924506893],
        [-1.914276967878095, 52.67081549230912],
      ],
    ]);

    const isInPoly = turf.booleanPointInPolygon(
      [data.address.fullAddress.longitude, data.address.fullAddress.latitude],
      poly
    );

    if (data.route.value === RouteOption.HOSPITAL && !isInPoly) {
      setFormError('This delivery location is not allowed for this route');
      return;
    }

    setFormSubmitting(true);
    const { address, type, wasteCollection, storageRequirement, sharpsBin, route, timeWindow, clinicCode, ...rest } =
      data;
    const payload: IAddDeliveryRequestDto = {
      uid: editing ? uid : uuid(),
      status: DeliveryStatus.SCHEDULED,
      address: address.fullAddress,
      type: type.value,
      orderByDate: data.date,
      processedPostcode: address.fullAddress.postcode.toLowerCase().replace(/\s/g, ''),
      wasteCollection: wasteCollection.value,
      storageRequirement: storageRequirement.value,
      sharpsBin: sharpsBin.value,
      route: route.value,
      timeWindow: timeWindow.value,
      clinicCode: clinicCode.value,
      ...(editing
        ? {
            createdAt: delivery?.createdAt.toDate(),
            createdBy: delivery?.createdBy,
            assignedRoute: delivery?.assignedRoute ?? DeliveryRouteAssignment.NONE,
          }
        : { createdAt: new Date(), createdBy: userData?.fullName, assignedRoute: DeliveryRouteAssignment.NONE }),
      ...rest,
    };
    try {
      await DeliveriesApiService.add(payload);
      dialog?.closeDialog();
      showSuccessFlag(
        'Success',
        editing
          ? `The delivery for ${data.firstName} ${data.lastName} was successfully updated`
          : `The delivery for ${data.firstName} ${data.lastName} was successfully created`,
        flags
      );
      if (refreshPage) {
        refreshPage();
      }
    } catch (error) {
      showErrorFlag(
        'Delivery creation failed',
        `Unable to create a delivery for ${data.firstName} ${data.lastName}, please try again.`,
        flags
      );
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4 overflow-y-auto'
        onSubmit={addDelivery}
        fields={formFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
        initialising={fetchingData}
        formErrorMessage={formError}
      />
    );
  };

  return (
    <SharedDialogBase
      title={editing ? 'Edit delivery' : 'Add delivery'}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditDeliveryDialog;
