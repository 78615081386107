import { useFlags } from '@atlaskit/flag';
import { IAddDeliveryRequestDto, IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { RescheduleDeliveryDialogFormFields } from 'core/config/form-fields';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { v4 as uuid } from 'uuid';
import { IRescheduleDeliveryDialogFormOutput } from './rescheduled-delivery-dialog-interface';
import { DeliveryRouteAssignment } from 'core/constants/delivery-route-assignment';
import { TimeWindow } from 'core/constants/time-window';

interface IRescheduleDeliveryDialog {
  delivery: IDelivery;
}

const RescheduleDeliveryDialog = ({ delivery }: IRescheduleDeliveryDialog) => {
  const [formFields, setFormFields] = useState(RescheduleDeliveryDialogFormFields);
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const navigate = useNavigate();
  const { userData } = useAuthState();

  useEffect(() => {
    setFormFields(
      RescheduleDeliveryDialogFormFields.map((field) => {
        if (field.key === 'notes') {
          field.defaultValue = delivery.notes;
        }
        return field;
      })
    );
  }, [delivery.notes]);

  const rescheduleDelivery = async (data: IRescheduleDeliveryDialogFormOutput) => {
    setFormSubmitting(true);
    const { uid, status, date, orderByDate, createdAt, createdBy, notes, route, assignedRoute, timeWindow, ...rest } =
      delivery;
    const payload: IAddDeliveryRequestDto = {
      uid: uuid(),
      status: DeliveryStatus.SCHEDULED,
      orderByDate: data.date,
      createdAt: new Date(),
      createdBy: userData?.fullName,
      route: route ?? 'regular',
      assignedRoute: assignedRoute ?? DeliveryRouteAssignment.NONE,
      timeWindow: timeWindow ?? TimeWindow.ANY,
      ...data,
      ...rest,
    };
    try {
      await DeliveriesApiService.add(payload);
      await DeliveriesApiService.update(delivery.uid, { rescheduled: true });
      dialog?.closeDialog();
      showSuccessFlag(
        'Success',
        `The delivery for ${delivery.firstName} ${delivery.lastName} was successfully rescheduled for ${dayjs(
          data.date
        ).format('DD/MM/YYYY')}`,
        flags
      );
      navigate('/deliveries');
    } catch (error) {
      showErrorFlag(
        'Delivery creation failed',
        `Unable to reschedule a delivery for ${delivery.firstName} ${delivery.lastName}, please try again.`,
        flags
      );
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4 overflow-y-auto'
        onSubmit={rescheduleDelivery}
        fields={formFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
      />
    );
  };
  return <SharedDialogBase title='Reschedule delivery' customContentTemplate={customContent()} showButtons={false} />;
};

export default RescheduleDeliveryDialog;
