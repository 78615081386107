export const SharpsBinOption = {
  NO: 'no',
  YELLOW: 'yellow',
  CYTO: 'cyto',
};

export const SharpsBinOptions: ISharpsBinOption[] = [
  {
    label: 'No',
    key: SharpsBinOption.NO,
  },
  {
    label: 'Yellow',
    key: SharpsBinOption.YELLOW,
  },
  {
    label: 'Cyto',
    key: SharpsBinOption.CYTO,
  },
];

export const getSharpsBinOptionFromKey = (key: string) => {
  const sbo = SharpsBinOptions.find((sbo) => sbo.key === key);
  return sbo;
};

export const getSharpsBinOptionNameFromKey = (key: string) => {
  const sbo = SharpsBinOptions.find((sbo) => sbo.key === key);
  return sbo?.label ?? 'Unknown';
};

export interface ISharpsBinOption {
  label: string;
  key: string;
}
