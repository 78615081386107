import { useFlags } from '@atlaskit/flag';
import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { DeliveryTab, deliveryTabs } from 'core/constants/delivery-tab';
import { useDialog } from 'core/providers/DialogProvider';
import { createAddressString } from 'core/utilities/address-string-builder';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { PageElement } from 'core/utilities/interface-helpers';
import { Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SharedButton from 'shared/components/buttons/button';
import SharedTabButton from 'shared/components/buttons/tab-button/tab-button';
import PageHeader from 'shared/components/page-header/page-header';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
import RescheduleDeliveryDialog from '../reschedule-delivery-dialog/reschedule-delivery-dialog';
import DeliveryOverviewDetails from './delivery-overview-details/delivery-overview-details';
import DeliveryOverviewNotes from './delivery-overview-notes/delivery-overview-notes';
import DeliveryOverviewProof from './delivery-overview-proof/delivery-overview-proof';

const DeliveryOverview = () => {
  // Local state
  const [loading, setLoading] = useState(true);
  const [delivery, setDelivery] = useState<IDelivery>();
  const [selectedTab, setSelectedTab] = useState<string>(DeliveryTab.NOTES);

  // Hooks
  const { uid } = useParams();
  const flags = useFlags();
  const dialog = useDialog();

  useEffect(() => {
    if (uid) {
      const handleSnapshot = (doc: IDelivery) => {
        setDelivery(doc);
        setLoading(false);
      };
      const handleSubscriptionError = (error: any) => {
        showErrorFlag('An error occurred', 'This customer could not be loaded, they may have been removed.', flags);
      };
      let unsubscribe: Unsubscribe;
      unsubscribe = DeliveriesApiService.subscribeToDelivery(uid, handleSnapshot, handleSubscriptionError);
      return () => {
        unsubscribe();
      };
    }
  }, [flags, uid]);

  const getTabContent = (delivery: IDelivery) => {
    switch (selectedTab) {
      case DeliveryTab.PROOF:
        return <DeliveryOverviewProof delivery={delivery} />;
      case DeliveryTab.NOTES:
        return <DeliveryOverviewNotes dispenserNote={delivery.notes} driverNote={delivery.driverNote} />;
    }
  };

  const headerActions: PageElement[] = [
    ...(delivery?.status === DeliveryStatus.UNFULFILLED
      ? [
          {
            key: 'reschedule',
            element: (
              <SharedButton
                onClick={() => dialog?.openDialog(<RescheduleDeliveryDialog delivery={delivery!} />)}
                type='button'
                appearance='default'
                label={delivery.rescheduled === true ? 'Rescheduled' : 'Reschedule delivery'}
                disabled={delivery.rescheduled === true}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <>
        {!delivery || loading ? (
          <PageHeaderSkeleton actions={[]} subtitle={true} />
        ) : (
          <>
            <PageHeader
              title={`${delivery.firstName} ${delivery.lastName}`}
              subtitle={createAddressString([...delivery.address.formatted_address, delivery.address.postcode])}
              showBack={true}
              actions={headerActions}
            />
            <div className='flex flex-row py-6'>
              <div>
                <DeliveryOverviewDetails delivery={delivery} />
              </div>
              <div className='pl-6 w-full'>
                <div className='relative'>
                  <div className='flex relative z-10'>
                    {deliveryTabs.map((tab) => (
                      <SharedTabButton key={tab.key} changeTab={setSelectedTab} tab={tab} currentTab={selectedTab} />
                    ))}
                  </div>
                  <div className='absolute bottom-0 left-0 right-0 h-px bg-gray-200' />
                </div>
                <div className='mt-6'>{getTabContent(delivery)}</div>
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default DeliveryOverview;
