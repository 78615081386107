import { Route, Routes } from 'react-router-dom';
import RoutesList from './routes-list/routes-list';
import RoutesOverview from './routes-overview/routes-overview';

const RoutesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<RoutesOverview />} />
      <Route path='view' element={<RoutesList />} />
    </Routes>
  );
};

export default RoutesRouting;
