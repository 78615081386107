import { useFlags } from '@atlaskit/flag';
import { IDelivery, IUpdateDeliveryRequestDto } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { SelectOption } from 'core/utilities/interface-helpers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import Select from '@atlaskit/select';
import TextArea from '@atlaskit/textarea';
import { ButtonGroup } from '@atlaskit/button';
import { FormFooter } from '@atlaskit/form';
import SharedButton from 'shared/components/buttons/button';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import { AlertTriangle } from 'react-feather';
interface IFailedDeliveryDialog {
  delivery: IDelivery;
}

interface IFailedDeliverForm {
  reason?: string;
  otherNote?: string;
}

const FailedDeliveryDialog = ({ delivery }: IFailedDeliveryDialog) => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<IFailedDeliverForm>({});
  const [formError, setFormError] = useState<string>();

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const navigate = useNavigate();

  const reasonOptions = [
    {
      label: 'Patient - not available',
      value: 'pna',
    },
    {
      label: 'Patient - not home',
      value: 'pnh',
    },
    {
      label: 'Patient - refused delivery',
      value: 'prd',
    },
    {
      label: 'D - Hive - vehicle issue',
      value: 'dvi',
    },
    {
      label: 'D - Hive - time constraint',
      value: 'dtc',
    },
    {
      label: 'D - Hive - address not located',
      value: 'danl',
    },
    {
      label: 'Pride - wrong address',
      value: 'dwa',
    },
    {
      label: 'Pride - wrong package',
      value: 'dwp',
    },
    {
      label: 'Pride - missing package',
      value: 'dmp',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  const isFormValid = () => {
    if (!formValues.reason) {
      setFormError('Please select a reason');
      return false;
    }

    if (formValues.reason === 'other' && !formValues.otherNote) {
      setFormError('Please provide further details');
      return false;
    }

    return true;
  };

  // Page specifics
  const updateDelivery = async () => {
    try {
      setFormSubmitting(true);
      const valid = isFormValid();

      if (!valid) {
        setFormSubmitting(false);
        return;
      }

      const driverNote =
        formValues.reason === 'other'
          ? formValues.otherNote
          : reasonOptions.find((x) => x.value === formValues.reason)?.label;

      const payload: IUpdateDeliveryRequestDto = {
        driverNote,
        status: DeliveryStatus.UNFULFILLED,
      };
      await DeliveriesApiService.update(delivery.uid, payload);
      dialog?.closeDialog();
      navigate(-1);
      showSuccessFlag('Delivery updated', 'The delivery was successfully updated', flags);
    } catch (error) {
      showErrorFlag('Delivery update failed', `Unable to update this delivery, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const handleValueChange = (key: string, value?: string) => {
    setFormError(undefined);
    setFormValues((prevState) => {
      return { ...prevState, [key]: value };
    });
  };

  const customContent = () => {
    return (
      <div className='p-4'>
        <p className='label-02 font-semibold mb-1 text-gray-500'>Reason</p>
        <Select<SelectOption>
          defaultValue={reasonOptions.find((x) => x.value === formValues.reason)}
          options={reasonOptions}
          onChange={(value) => handleValueChange('reason', value?.value)}
          isClearable={true}
          isSearchable={false}
        />
        {formValues.reason === 'other' && (
          <>
            <p className='label-02 font-semibold mb-1 mt-4 text-gray-500'>Other reason</p>
            <TextArea
              minimumRows={3}
              onChange={(e) => handleValueChange('otherNote', e.currentTarget.value)}
              defaultValue={formValues.otherNote ?? ''}
            />
          </>
        )}
        {formError && (
          <div className='mt-6 label-01 text-red-600 flex items-center'>
            <AlertTriangle className='mr-3' />
            {formError}
          </div>
        )}
        <FormFooter>
          <ButtonGroup>
            <SharedButton onClick={() => dialog?.closeDialog()} label='Cancel' appearance='subtle' type='button' />
            <SharedLoadingButton
              onClick={() => updateDelivery()}
              type='button'
              appearance='primary'
              isLoading={formSubmitting}
              label='Submit'
            />
          </ButtonGroup>
        </FormFooter>
      </div>
    );
  };

  return (
    <SharedDialogBase title='Failed delivery/collection' customContentTemplate={customContent()} showButtons={false} />
  );
};

export default FailedDeliveryDialog;
