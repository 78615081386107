import { IDeliveryMeta } from 'core/api/meta/meta-api-interface';
import MetaApiService from 'core/api/meta/meta-api.service';
import { useEffect, useState } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import Toggle from '@atlaskit/toggle';
import { useFlags } from '@atlaskit/flag';
import { Unsubscribe } from 'firebase/firestore';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { getRouteOptionNameFromKey } from 'core/constants/route-options';

const SettingsOverview = () => {
  const flags = useFlags();
  const [meta, setMeta] = useState<IDeliveryMeta>();

  useEffect(() => {
    const handleSnapshot = (doc: IDeliveryMeta) => {
      setMeta(doc);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Settings could not be loaded, please try again.', flags);
    };
    let unsubscribe: Unsubscribe;
    unsubscribe = MetaApiService.subscribeToMeta('deliveries', handleSnapshot, handleSubscriptionError);
    return () => {
      unsubscribe();
    };
  }, [flags]);

  const handleSettingsChange = async (key: string, enabled: boolean) => {
    try {
      switch (key) {
        case 'sendSMSMessages':
          await MetaApiService.update('deliveries', { sendSMSMessages: enabled });
          break;
        default:
          const newValue = meta?.possibleRoutes.map((route) =>
            route.key !== key
              ? route
              : {
                  ...route,
                  enabled,
                }
          );
          await MetaApiService.update('deliveries', { possibleRoutes: newValue });
      }
      showSuccessFlag('Update successful', 'The setting was successfully updated.', flags);
    } catch (error) {
      console.log(error);
      showErrorFlag('An error occurred', 'Settings could not be updated, please try again.', flags);
    }
  };

  return (
    <>
      <PageHeader title='System settings' />
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-6 h-max'>
        <div className='bg-white w-full rounded-md shadow-md h-fit'>
          <div className='flex items-center p-4 border-b'>
            <p className='headline-05'>Notifications</p>
          </div>
          <div className='border-b last:border-0 p-4 flex justify-between items-center'>
            <p>SMS Notifications</p>
            <Toggle
              size='large'
              onChange={() => handleSettingsChange('sendSMSMessages', !meta?.sendSMSMessages)}
              isChecked={meta?.sendSMSMessages}
            />
          </div>
        </div>
        <div className='bg-white w-full rounded-md shadow-md h-fit'>
          <div className='flex items-center p-4 border-b'>
            <p className='headline-05'>Routes</p>
          </div>
          {meta?.possibleRoutes.map((route) => (
            <div className='border-b last:border-0 p-4 flex justify-between items-center'>
              <p>{getRouteOptionNameFromKey(route.key)}</p>
              <Toggle
                size='large'
                onChange={() => handleSettingsChange(route.key, !route.enabled)}
                isChecked={route.enabled}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SettingsOverview;
