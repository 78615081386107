import { ButtonGroup } from '@atlaskit/button';
import { ButtonType } from 'core/enums/button-type';
import { useDialog } from 'core/providers/DialogProvider';
import React from 'react';
import SharedButton from '../buttons/button';
import { IButton } from '../buttons/button-interface';
import SharedLoadingButton from '../buttons/loading-button';
import { ILoadingButton } from '../buttons/loading-button-interface';
import { ISharedDialogBase, ISharedDialogButton } from './dialog-base-interface';

const SharedDialogBase = ({
  title,
  textContent,
  customContentTemplate,
  showButtons = true,
  customButtons,
}: ISharedDialogBase) => {
  const dialog = useDialog();
  const defaultButtons: ISharedDialogButton[] = [
    {
      key: 'close',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'primary',
        label: 'Close',
        type: 'button',
      },
    },
  ];

  const buttons = customButtons ?? defaultButtons;
  return (
    <div className='rounded-lg bg-white w-full lg:w-[600px] flex flex-col'>
      <div className='border-b p-4'>
        <p className='headline-05 font-semibold'>{title}</p>
      </div>
      {textContent && <p className='body-02 py-6 px-4'>{textContent}</p>}
      {customContentTemplate && <>{customContentTemplate}</>}
      {showButtons && (
        <div className='border-t p-4 flex justify-end'>
          <ButtonGroup>
            {buttons.map((button) => (
              <React.Fragment key={button.key}>
                {button.type === ButtonType.Button ? (
                  <SharedButton {...(button.buttonProps as IButton)} />
                ) : (
                  <SharedLoadingButton {...(button.buttonProps as ILoadingButton)} />
                )}
              </React.Fragment>
            ))}
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default SharedDialogBase;
