import { firestore, functions } from 'core/config/firebase';
import {
  addDoc,
  CollectionReference,
  deleteDoc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  onSnapshot,
  Query,
  QueryDocumentSnapshot,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { HttpsCallableOptions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, StorageReference, uploadString } from 'firebase/storage';
import { IGetData, IListAllData } from './api-interface';

const listAllData = async <T>(query: Query<DocumentData>, queryLimit?: number): Promise<IListAllData<T>> => {
  const converter = typeConverter<T>();
  const querySnapshot = await getDocs(query.withConverter(converter));
  const result: IListAllData<T> = {
    data: [],
    count: querySnapshot.size,
    lastVisible: querySnapshot.size === queryLimit ? querySnapshot.docs[querySnapshot.docs.length - 1] : undefined,
  };
  querySnapshot.forEach((doc) => {
    result.data.push(doc.data());
  });
  return result;
};

const getData = async <T>(docRef: DocumentReference): Promise<IGetData<T>> => {
  const converter = typeConverter<T>();
  const docSnapshot = await getDoc(docRef.withConverter(converter));
  if (docSnapshot.exists()) {
    return {
      data: docSnapshot.data(),
    };
  } else {
    throw new Error('This document does not exist');
  }
};

const add = async <T>(collectionRef: CollectionReference, docContent: T) => {
  return addDoc(collectionRef, docContent);
};

const set = async <T>(docRef: DocumentReference, docContent: T) => {
  return setDoc(docRef, docContent);
};

const update = async <T>(docRef: DocumentReference, docContent: T) => {
  return updateDoc(docRef, docContent);
};

const remove = async (docRef: DocumentReference) => {
  return deleteDoc(docRef);
};

const callFunction = async <T>(payload: any, name: string, options?: HttpsCallableOptions) => {
  const fbFunction = httpsCallable(functions, name, options);
  const result = await fbFunction(payload);
  return result.data as T;
};

const subscribeToDoc = <T>(docRef: DocumentReference, resultHandler: Function, errorHandler: Function) => {
  const converter = typeConverter<T>();
  return onSnapshot(
    docRef.withConverter(converter),
    (doc) => {
      resultHandler(doc.data());
    },
    (error) => {
      errorHandler(error);
    }
  );
};

const subscribeToCollection = (
  collectionRef: CollectionReference | Query,
  resultHandler: Function,
  errorHandler: Function
) => {
  return onSnapshot(
    collectionRef,
    (snapShot) => {
      resultHandler(snapShot);
    },
    (error) => {
      errorHandler(error);
    }
  );
};

const typeConverter = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (doc: QueryDocumentSnapshot<DocumentData>) => doc.data() as T,
});

const uploadDataUrl = (storageRef: StorageReference, dataUrl: string) => {
  const metadata = {
    contentType: 'image/jpeg',
  };
  return uploadString(storageRef, dataUrl, 'data_url', metadata);
};

const getFileDownloadUrl = (storageRef: StorageReference) => {
  return getDownloadURL(storageRef);
};

const batchRemove = (docRefs: DocumentReference[]) => {
  const batch = writeBatch(firestore);
  docRefs.forEach((ref) => {
    batch.delete(ref);
  });
  return batch.commit();
};

const ApiService = {
  listAllData,
  getData,
  callFunction,
  add,
  set,
  update,
  remove,
  subscribeToDoc,
  subscribeToCollection,
  uploadDataUrl,
  getFileDownloadUrl,
  batchRemove,
};

export default ApiService;
