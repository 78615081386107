import { auth } from 'core/config/firebase';
import { getRoleNameFromKey } from 'core/config/roles';
import { useAuthState } from 'core/providers/AuthProvider';
import { signOut } from 'firebase/auth';

interface IHeader {
  title: string;
}

const SharedHeader = ({ title }: IHeader) => {
  const { userData, userRole } = useAuthState();

  const logOut = () => {
    signOut(auth);
  };

  return (
    <div className='fixed h-[64px] bg-primary w-full flex'>
      <div className='w-full flex items-center justify-between px-6 text-white'>
        <p className='headline-app'>{title}</p>
        <div className='text-right'>
          <p className='body-02'>
            {userData?.fullName} {`(${getRoleNameFromKey(userRole!)})`}
          </p>
          <p className='body-03 opacity-80 cursor-pointer' onClick={() => logOut()}>
            Log Out
          </p>
        </div>
      </div>
    </div>
  );
};

export default SharedHeader;
