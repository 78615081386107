import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { useEffect, useState } from 'react';
import SkeletonElement from 'shared/components/layout/skeleton-element';

interface IDeliveryOverviewProof {
  delivery: IDelivery;
}

const DeliveryOverviewProof = ({ delivery }: IDeliveryOverviewProof) => {
  const [image, setImage] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prepare = async () => {
      setStatus(delivery.status);
      let proofUrl: string;
      if (delivery.proofMethod === 'signature') {
        proofUrl = await DeliveriesApiService.getDeliverySignature(delivery.uid);
      } else {
        proofUrl = await DeliveriesApiService.getDeliveryImage(delivery.uid);
      }
      setImage(proofUrl);
      setLoading(false);
    };
    if (delivery.status === DeliveryStatus.DELIVERED) {
      prepare();
    } else {
      setStatus(delivery.status);
      setLoading(false);
    }
  }, [delivery.proofMethod, delivery.status, delivery.uid]);

  const getContent = () => {
    switch (status) {
      case DeliveryStatus.DELIVERED:
        return <img className='max-h-[500px]' src={image} alt='' />;
      case DeliveryStatus.UNFULFILLED:
        return <p>The driver was unable to complete this delivery</p>;
      default:
        return <p className='text-stone-300'>This delivery has not been completed yet</p>;
    }
  };
  return (
    <div className='bg-white rounded-md shadow-md'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Proof of delivery/collection</p>
      </div>
      <div className='p-4'>
        {loading && <SkeletonElement width='340px' height='500px' />}
        {getContent()}
      </div>
    </div>
  );
};

export default DeliveryOverviewProof;
