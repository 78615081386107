import { Route, Routes } from 'react-router-dom';
import DeliverySignature from './delivery-signature/delivery-signature';
import DriverDelivery from './driver-delivery/driver-delivery';
import DriverOverview from './driver-overview/driver-overview';
import AppDownload from './app-download/app-download';

const DriverRouting = () => {
  return (
    <Routes>
      <Route path='' element={<DriverOverview />} />
      <Route path=':uid' element={<DriverDelivery />} />
      <Route path=':uid/signature' element={<DeliverySignature />} />
      <Route path='app-download' element={<AppDownload />} />
    </Routes>
  );
};

export default DriverRouting;
