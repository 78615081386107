export const RouteOption = {
  REGULAR: 'regular',
  SAME_DAY_MORNING: 'sameDayMorning',
  SAME_DAY_AFTERNOON: 'sameDayAfternoon',
  SAME_DAY_EVENING: 'sameDayEvening',
  SAME_DAY_AFTERNOON_2: 'sameDayAfternoon2',
  HOSPITAL: 'hospital',
};

export const RouteOptions = [
  {
    label: 'Full Day (9AM - 5:30PM)',
    value: RouteOption.REGULAR,
    section: 'regular',
  },
  {
    label: 'Same Day Morning (10AM - 1PM)',
    value: RouteOption.SAME_DAY_MORNING,
    section: 'regular',
  },
  {
    label: 'Same Day Afternoon 1 (1:30PM - 5:30PM)',
    value: RouteOption.SAME_DAY_AFTERNOON,
    section: 'regular',
  },
  {
    label: 'Same Day Evening (6PM - 9PM)',
    value: RouteOption.SAME_DAY_EVENING,
    section: 'assigned',
  },
  {
    label: 'Same Day Afternoon 2 (1PM - 5PM)',
    value: RouteOption.SAME_DAY_AFTERNOON_2,
    section: 'regular',
  },
  {
    label: 'Hospital',
    value: RouteOption.HOSPITAL,
    section: 'assigned',
  },
];

export const getRouteOptionFromKey = (key: string) => {
  const route = RouteOptions.find((route) => route.value === key);
  return route;
};

export const getRouteOptionNameFromKey = (key: string) => {
  const route = RouteOptions.find((route) => route.value === key);
  return route?.label ?? 'Unknown';
};
