export const Roles: IRole[] = [
  {
    label: 'Admin',
    key: 'admin',
  },
  {
    label: 'Homecare Dispenser',
    key: 'homecareDispenser',
  },
  {
    label: 'Driver',
    key: 'driver',
  },
];

export const getRoleFromKey = (key: string) => {
  const role = Roles.find((role) => role.key === key);
  return role;
};

export const getRoleNameFromKey = (key: string) => {
  const role = Roles.find((role) => role.key === key);
  return role?.label ?? 'Unknown';
};

export interface IRouteRoles {
  roles?: Role[];
}

export interface IRole {
  label: string;
  key: Role;
}

export type Role = 'admin' | 'homecareDispenser' | 'driver';
