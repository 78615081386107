import { useDialog } from 'core/providers/DialogProvider';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import SharedButton from 'shared/components/buttons/button';
import SharedMobileHeader from 'shared/components/layout/mobile-header';
import SuccessfulDeliveryDialog from '../successful-delivery-dialog/successful-delivery-dialog';

const DeliverySignature = () => {
  // Local State
  const [signature, setSignature] = useState<SignatureCanvas | null>(null);
  const [error, setError] = useState(false);
  const dialog = useDialog();
  const { uid } = useParams();

  const submitSignature = () => {
    setError(false);
    if (!signature || signature.isEmpty()) {
      setError(true);
      return;
    }
    const dataUrl = signature.getTrimmedCanvas().toDataURL('image/png');
    dialog?.openDialog(<SuccessfulDeliveryDialog deliveryUid={uid!} signatureDataUrl={dataUrl} />);
  };

  return (
    <div className='bg-primary'>
      <SharedMobileHeader backButton />
      <div className='p-4 bg-white rounded-t-2xl'>
        <p className='headline-05 mb-2'>Customer signature</p>
        <div className='w-full flex flex-col'>
          <SignatureCanvas
            penColor='black'
            canvasProps={{
              className: `border border-black portrait:hidden h-[200px]`,
            }}
            ref={(ref) => {
              setSignature(ref);
            }}
          />
          <div className='landscape:hidden flex-grow body-02 text-gray-500'>
            Please rotate your device to capture a signature
          </div>
          <div className='flex justify-end space-x-3 pt-4 items-center portrait:hidden'>
            {error && <p className='body-02 text-red-500 flex-grow'>Please sign before continuing.</p>}
            <SharedButton type='submit' appearance='primary' label='Continue' onClick={() => submitSignature()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliverySignature;
