import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { useNavigate } from 'react-router-dom';

interface IDeleteDeliveryDialog {
  deliveries: string[];
  refreshPage?: Function;
  navigateTo?: string;
}

const DeleteDeliveryDialog = ({ deliveries, refreshPage, navigateTo }: IDeleteDeliveryDialog) => {
  const [loading, setLoading] = useState(false);
  // Hooks
  const dialog = useDialog();
  const flags = useFlags();
  const multiple = deliveries.length > 1;
  const navigate = useNavigate();

  const deleteDeliveries = async () => {
    setLoading(true);
    try {
      await DeliveriesApiService.removeDeliveries(deliveries);
      showSuccessFlag(
        'Deletion successful',
        swapString('All deliveries were successfully deleted', 'The delivery was successfully deleted'),
        flags
      );
      dialog?.closeDialog();
      if (refreshPage) {
        refreshPage();
      }
      if (navigateTo) {
        navigate(navigateTo);
      }
    } catch (error) {
      setLoading(false);
      showErrorFlag(
        'Deletion failed',
        swapString(
          'Unable to delete these deliveries, please try again',
          'Unable to delete this delivery, please try again'
        ),
        flags
      );
    }
  };

  const swapString = (multiString: string, singleString: string) => (multiple ? multiString : singleString);

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'deleteUser',
      type: ButtonType.LoadingButton,
      buttonProps: {
        isLoading: loading,
        onClick: () => deleteDeliveries(),
        appearance: 'danger',
        label: swapString('Delete deliveries', 'Delete delivery'),
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={swapString(`Delete ${deliveries.length} deliveries`, 'Delete this delivery?')}
      textContent={swapString(
        'Are you sure you want to delete these deliveries? This action cannot be undone.',
        'Are you sure you want to delete this delivery? This action cannot be undone.'
      )}
      customButtons={customButtons}
    />
  );
};

export default DeleteDeliveryDialog;
