export const ClinicCodes = [
  {
    label: 'ACCIDENT & EMERGENCY (OPD)',
    value: 'ACCIDENT & EMERGENCY (OPD)',
  },
  {
    label: 'AMBULATORY CARE CENTRE (OPD)',
    value: 'AMBULATORY CARE CENTRE (OPD)',
  },
  {
    label: 'BREAST UNIT (OPD)',
    value: 'BREAST UNIT (OPD)',
  },
  {
    label: 'CARDIOLOGY (OPD)',
    value: 'CARDIOLOGY (OPD)',
  },
  {
    label: "CHILDREN'S EMERGENCY (OPD)",
    value: "CHILDREN'S EMERGENCY (OPD)",
  },
  {
    label: 'COMBINED DAY UNIT',
    value: 'COMBINED DAY UNIT',
  },
  {
    label: 'DAY CASE (OPD)',
    value: 'DAY CASE (OPD)',
  },
  {
    label: 'DERMATOLOGY (OPD)',
    value: 'DERMATOLOGY (OPD)',
  },
  {
    label: 'DIABETES (OPD)',
    value: 'DIABETES (OPD)',
  },
  {
    label: 'ENDOCRINOLOGY (OPD)',
    value: 'ENDOCRINOLOGY (OPD)',
  },
  {
    label: 'ENT (OPD)',
    value: 'ENT (OPD)',
  },
  {
    label: 'GASTRO-ENTEROLOGY (OPD)',
    value: 'GASTRO-ENTEROLOGY (OPD)',
  },
  {
    label: 'GU MEDICINE (OPD)',
    value: 'GU MEDICINE (OPD)',
  },
  {
    label: 'HAEMATOLOGY (OPD)',
    value: 'HAEMATOLOGY (OPD)',
  },
  {
    label: 'HAND SURGERY (OPD)',
    value: 'HAND SURGERY (OPD)',
  },
  {
    label: 'HEPATOLOGY (OPD)',
    value: 'HEPATOLOGY (OPD)',
  },
  {
    label: 'HOME DELIVERY - MEDICINE',
    value: 'HOME DELIVERY - MEDICINE',
  },
  {
    label: 'MAX-FAX (OPD)',
    value: 'MAX-FAX (OPD)',
  },
  {
    label: 'MEDICINE FOR THE ELDERLY (OPD)',
    value: 'MEDICINE FOR THE ELDERLY (OPD)',
  },
  {
    label: 'NEUROLOGY (OPD)',
    value: 'NEUROLOGY (OPD)',
  },
  {
    label: 'ONCOLOGY (OPD)',
    value: 'ONCOLOGY (OPD)',
  },
  {
    label: 'OPHTHALMOLOGY (OPD)',
    value: 'OPHTHALMOLOGY (OPD)',
  },
  {
    label: 'ORTHOPAEDICS (OPD)',
    value: 'ORTHOPAEDICS (OPD)',
  },
  {
    label: 'PAIN MANAGEMENT (OPD)',
    value: 'PAIN MANAGEMENT (OPD)',
  },
  {
    label: 'PALLIATIVE MEDICINE (MACMILLAN) OPD',
    value: 'PALLIATIVE MEDICINE (MACMILLAN) OPD',
  },
  {
    label: 'RENAL (OPD)',
    value: 'RENAL (OPD)',
  },
  {
    label: 'RESPIRATORY MEDICINE (OPD)',
    value: 'RESPIRATORY MEDICINE (OPD)',
  },
  {
    label: 'RHEUMATOLOGY (OPD)',
    value: 'RHEUMATOLOGY (OPD)',
  },
  {
    label: 'STROKE (OPD)',
    value: 'STROKE (OPD)',
  },
  {
    label: 'SURGERY (OPD)',
    value: 'SURGERY (OPD)',
  },
  {
    label: 'UROLOGY (OPD)',
    value: 'UROLOGY (OPD)',
  },
  {
    label: 'Z GU MEDICINE (HIV) OPD',
    value: 'Z GU MEDICINE (HIV) OPD',
  },
];
