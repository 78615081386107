import { faker } from '@faker-js/faker/locale/en_GB';

export interface IAddressSuggestions {
  suggestions: IAddressSuggestion[];
}

export interface IAddressSuggestion {
  address: string;
  url: string;
  id: string;
}

export interface IAddress {
  postcode: string;
  latitude: number;
  longitude: number;
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: number;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
  residential: boolean;
}

export const createRandomAddress = (postcode: string): IAddress => ({
  postcode,
  latitude: parseFloat(faker.address.latitude(54, 52, 6)),
  longitude: parseFloat(faker.address.longitude(-0.5, -3, 6)),
  formatted_address: faker.address.streetAddress(true).split(','),
  thoroughfare: '',
  building_name: '',
  sub_building_name: '',
  sub_building_number: '',
  building_number: 1,
  line_1: faker.address.streetAddress(),
  line_2: '',
  line_3: '',
  line_4: '',
  locality: faker.address.city(),
  town_or_city: faker.address.city(),
  county: faker.address.county(),
  district: faker.address.county(),
  country: faker.address.country(),
  residential: faker.datatype.boolean(),
});
