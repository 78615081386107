import { IRoute } from 'core/api/routes/routes-api-interface';
import { RouteOption } from 'core/constants/route-options';

const ClassStringBuilder = (classes: (string | false)[]) => classes.filter(Boolean).join(' ');

export const getSameDayRouteDurationStyle = (route: IRoute, routeType: string) => {
  switch (routeType) {
    case RouteOption.SAME_DAY_MORNING:
      return route.durationRaw > 10800 ? { color: 'red' } : {};
    case RouteOption.SAME_DAY_AFTERNOON:
      return route.durationRaw > 14400 ? { color: 'red' } : {};
    case RouteOption.SAME_DAY_AFTERNOON_2:
      return route.durationRaw > 14400 ? { color: 'red' } : {};
    case RouteOption.SAME_DAY_EVENING:
      return route.durationRaw > 10800 ? { color: 'red' } : {};
    case RouteOption.REGULAR:
      return route.durationRaw > 30600 ? { color: 'red' } : {};
    default:
      return;
  }
};

export default ClassStringBuilder;
