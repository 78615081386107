import { MapPin, MessageCircle, PieChart, Settings, Truck, Users } from 'react-feather';

export const SidebarOptions = [
  {
    icon: <PieChart />,
    label: 'Dashboard',
    route: 'dashboard',
    roles: ['admin', 'homecareDispenser'],
  },
  {
    icon: <Truck />,
    label: 'Deliveries',
    route: 'deliveries',
    roles: ['admin', 'homecareDispenser'],
  },
  {
    icon: <MapPin />,
    label: 'Routes',
    route: 'routes',
    roles: ['admin', 'homecareDispenser'],
  },
  {
    icon: <MessageCircle />,
    label: 'Driver Messages',
    route: 'driver-messages',
    roles: ['admin', 'homecareDispenser'],
  },
  {
    icon: <Users />,
    label: 'Users',
    route: 'users',
    roles: ['admin'],
  },
  {
    icon: <Settings />,
    label: 'Settings',
    route: 'settings',
    roles: ['admin'],
  },
];
