import { DeliveryTypes } from 'core/constants/delivery-types';
import { SharpsBinOptions } from 'core/constants/sharps-bin-options';
import { StorageRequirements } from 'core/constants/storage-requirement';
import { WasteCollectionOptions } from 'core/constants/waste-collection';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { getToday, getTomorrow } from 'core/utilities/date-helpers';
import { ISharedFormField } from 'shared/components/form/form-interface';
import { Roles } from './roles';
import { DeliveryRouteAssignments } from 'core/constants/delivery-route-assignment';
import { TimeWindows } from 'core/constants/time-window';
import { ClinicCodes } from 'core/constants/clinic-code';

export const LoginFormFields: ISharedFormField[] = [
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
  {
    key: 'password',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Password',
    required: true,
  },
];

export const ForgotPasswordFormFields: ISharedFormField[] = [
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
];

export const ResetPasswordFormFields: ISharedFormField[] = [
  {
    key: 'newPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'New Password',
    required: true,
  },
  {
    key: 'confirmNewPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Confirm Password',
    required: true,
  },
];

export const SetPasswordFormFields: ISharedFormField[] = [
  {
    key: 'currentPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Current Password',
    required: true,
  },
  {
    key: 'newPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'New Password',
    required: true,
  },
  {
    key: 'confirmNewPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Confirm New Password',
    required: true,
  },
];

export const AddUserFormFields: ISharedFormField[] = [
  {
    key: 'fullName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Full Name',
    required: true,
  },
  {
    key: 'emailAddress',
    control: ControlType.TextField,
    type: InputType.Email,
    label: 'Email address',
    required: true,
  },
  {
    key: 'role',
    control: ControlType.Select,
    label: 'User Role',
    required: true,
    options: Roles.map((role) => ({ value: role.key, label: role.label })),
  },
];

export const AddDeliveryFormFields: ISharedFormField[] = [
  {
    key: 'route',
    control: ControlType.Select,
    options: DeliveryRouteAssignments,
    label: 'Route',
    required: true,
    defaultValue: DeliveryRouteAssignments[0],
  },
  {
    key: 'firstName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'First name',
    required: true,
  },
  {
    key: 'lastName',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Last name',
    required: true,
  },
  {
    key: 'hospitalNumber',
    control: ControlType.TextField,
    type: InputType.Text,
    label: 'Hospital number',
    required: false,
  },
  {
    key: 'date',
    control: ControlType.DatePicker,
    label: 'Delivery date',
    required: true,
    defaultValue: getTomorrow(),
    minDate: getToday(),
  },
  {
    key: 'timeWindow',
    control: ControlType.Select,
    options: TimeWindows,
    label: 'Time window',
    required: true,
    defaultValue: TimeWindows[0],
  },
  {
    key: 'address',
    control: ControlType.AddressSearch,
    label: 'Address',
    required: true,
  },
  {
    key: 'phoneNumber',
    control: ControlType.TextField,
    type: InputType.Tel,
    label: 'Phone number',
    required: true,
  },
  {
    key: 'parcelCount',
    control: ControlType.TextField,
    type: InputType.Number,
    label: 'Number of parcels',
    required: true,
    defaultValue: '1',
  },
  {
    key: 'clinicCode',
    control: ControlType.Select,
    options: ClinicCodes,
    label: 'Clinic code',
    required: true,
  },
  {
    key: 'sharpsBin',
    control: ControlType.Select,
    label: 'Sharps bin required',
    required: true,
    options: SharpsBinOptions.map((sbo) => ({
      label: sbo.label,
      value: sbo.key,
    })),
  },
  {
    key: 'wasteCollection',
    control: ControlType.Select,
    label: 'Waste collection required',
    required: true,
    options: WasteCollectionOptions.map((wc) => ({
      label: wc.label,
      value: wc.key,
    })),
  },
  {
    key: 'storageRequirement',
    control: ControlType.Select,
    label: 'Storage requirement',
    required: true,
    options: StorageRequirements.map((sr) => ({
      label: sr.label,
      value: sr.key,
    })),
  },
  {
    key: 'type',
    control: ControlType.Select,
    label: 'Delivery type',
    required: true,
    options: DeliveryTypes.map((type) => ({ value: type.key, label: type.label })),
  },
  {
    key: 'notes',
    control: ControlType.TextArea,
    label: 'Additional notes',
    required: false,
  },
];

export const SuccessfulDeliveryFormFields = [
  {
    key: 'note',
    control: ControlType.TextArea,
    label: 'Optional note',
    required: false,
  },
];

export const ExportDeliveriesDialogFormFields = [
  {
    key: 'exportType',
    control: ControlType.Select,
    label: 'Export type',
    options: [
      {
        label: 'CSV',
        value: 'csv',
      },
      {
        label: 'Successful Deliveries PDF',
        value: 'successfulDeliveriesPdf',
      },
    ],
    defaultValue: {
      label: 'CSV',
      value: 'csv',
    },
    required: true,
  },
  {
    key: 'startDateString',
    control: ControlType.DatePicker,
    label: 'Date from',
    required: true,
    defaultValue: getToday(),
  },
  {
    key: 'endDateString',
    control: ControlType.DatePicker,
    label: 'Date to',
    required: true,
    defaultValue: getToday(),
  },
];

export const RescheduleDeliveryDialogFormFields: ISharedFormField[] = [
  {
    key: 'date',
    control: ControlType.DatePicker,
    label: 'New delivery date',
    required: true,
    defaultValue: getTomorrow(),
    minDate: getTomorrow(),
  },
  {
    key: 'notes',
    control: ControlType.TextArea,
    label: 'Additional notes',
    required: false,
  },
];
