import { useFlags } from '@atlaskit/flag';
import { IDriverMessage } from 'core/api/driver-messages/driver-messages-api-interface';
import DriverMessagesApiService from 'core/api/driver-messages/driver-messages-api.service';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface IDriverMessagesContext {
  messages: IDriverMessage[];
  unreadMessages: IDriverMessage[];
}

export const DriverMessagesContext = createContext<IDriverMessagesContext>({
  messages: [],
  unreadMessages: [],
});

export const useDriverMessagesState = () => {
  const state = useContext(DriverMessagesContext);
  return { ...state };
};

export const DriverMessagesProvider = () => {
  const [messages, setMessages] = useState<IDriverMessage[]>([]);
  const [unreadMessages, setUnreadMessages] = useState<IDriverMessage[]>([]);
  const flags = useFlags();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IDriverMessage>) => {
      const messageList: IDriverMessage[] = [];
      const unreadMessageList: IDriverMessage[] = [];
      querySnapshot.forEach((snap) => {
        const messageData = snap.data();
        messageList.push(messageData);
        if (!messageData.isRead) {
          unreadMessageList.push(messageData);
        }
      });
      setUnreadMessages(unreadMessageList);
      setMessages(messageList);
    };
    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Could not retrieve driver messages', flags);
    };
    const unsubscribeFromMessages = DriverMessagesApiService.subscribe(handleSnapshot, handleSubscriptionError);
    return () => {
      unsubscribeFromMessages();
    };
  }, [flags]);

  return (
    <DriverMessagesContext.Provider value={{ messages, unreadMessages }}>
      <Outlet />
    </DriverMessagesContext.Provider>
  );
};
