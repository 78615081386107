import { ITab } from "shared/components/buttons/tab-button/tab-button-interface";

export const DeliveryTab = {
  PROOF: 'proof',
  NOTES: 'notes',
};

export const deliveryTabs: ITab[] = [
  {
    label: 'Notes',
    key: DeliveryTab.NOTES,
  },
  {
    label: 'Proof of delivery/collection',
    key: DeliveryTab.PROOF,
  },
];

