import { useFlags } from '@atlaskit/flag';
import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { getRouteOptionNameFromKey, RouteOptions } from 'core/constants/route-options';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag, showSuccessFlag } from 'core/utilities/flags-helper';
import { SelectOption } from 'core/utilities/interface-helpers';
import { useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import { ISharedFormField } from 'shared/components/form/form-interface';

interface IDriverRoutesDialog {
  user: IUser;
}

const DriverRoutesDialog = ({ user }: IDriverRoutesDialog) => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();
  const DriverRoutesFormFields: ISharedFormField[] = [
    {
      key: 'routes',
      control: ControlType.SelectMulti,
      label: 'Available routes',
      options: RouteOptions,
      defaultValue: user.routes?.map((r) => ({
        value: r,
        label: getRouteOptionNameFromKey(r),
      })),
      required: true,
    },
  ];

  // Page specifics
  const saveRoutes = async (data: { routes: SelectOption[] }) => {
    setFormSubmitting(true);
    const payload = {
      routes: data.routes.map((r) => r.value),
    };
    try {
      await UsersApiService.update(user.uid, payload);
      showSuccessFlag('Driver updated', `The available routes for ${user.fullName} were successfully updated.`, flags);
      dialog?.closeDialog();
    } catch (error) {
      showErrorFlag('An error occurred', `Unable to update the routes for ${user.fullName}, please try again.`, flags);
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <>
        <SharedForm
          className='p-4'
          onSubmit={saveRoutes}
          fields={DriverRoutesFormFields}
          buttonLabel='Submit'
          loading={formSubmitting}
          cancelButton={cancelButton}
        />
      </>
    );
  };

  return <SharedDialogBase title='Select driver routes' customContentTemplate={customContent()} showButtons={false} />;
};

export default DriverRoutesDialog;
