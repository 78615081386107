import { SelectOption } from 'core/utilities/interface-helpers';
import { useEffect, useMemo, useState } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import Select from '@atlaskit/select';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import weekday from 'dayjs/plugin/weekday';
import ReportsApiService from 'core/api/reports/reports-api.service';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { CheckCircle, Truck, XCircle } from 'react-feather';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import { RouteOptions } from 'core/constants/route-options';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
dayjs.locale('en-gb');
dayjs.extend(weekday);

interface IDashboardData {
  [key: string]: {
    expectedDeliveries: number;
    successfulDeliveries: number;
    incompleteDeliveries: number;
  };
}

const DashboardView = () => {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<IDashboardData>();
  const [timeframe, setTimeframe] = useState<SelectOption>({
    value: 'week',
    label: 'This week',
  });
  const [selectedRoute, setSelectedRoute] = useState<SelectOption>({
    value: 'all',
    label: 'All routes',
  });
  const [lastUpdated, setLastUpdated] = useState<string>();

  const routeOptions = useMemo(() => [{ value: 'all', label: 'All routes' }, ...RouteOptions], []);

  useEffect(() => {
    const prepare = async () => {
      setLoading(true);
      const startDate = (timeframe.value === 'week' ? dayjs().weekday(0) : dayjs().date(1)).format('YYYY-MM-DD');
      const reports = await ReportsApiService.listAll({ startDate });
      console.log(reports);

      const counts: IDashboardData = {
        all: {
          expectedDeliveries: 0,
          successfulDeliveries: 0,
          incompleteDeliveries: 0,
        },
      };
      RouteOptions.forEach((option) => {
        counts[option.value] = {
          expectedDeliveries: 0,
          successfulDeliveries: 0,
          incompleteDeliveries: 0,
        };
      });
      reports.data.forEach((report) => {
        routeOptions.forEach((option) => {
          if (option.value === 'all') {
            counts.all.expectedDeliveries += report.expectedDeliveries;
            counts.all.successfulDeliveries += report.successfulDeliveries;
            counts.all.incompleteDeliveries += report.incompleteDeliveries;
          } else {
            counts[option.value].expectedDeliveries += report[option.value]?.expectedDeliveries ?? 0;
            counts[option.value].successfulDeliveries += report[option.value]?.successfulDeliveries ?? 0;
            counts[option.value].incompleteDeliveries += report[option.value]?.incompleteDeliveries ?? 0;
          }
        });
      });
      setDashboardData(counts);
      const lastDocCreated =
        reports.data.length > 0
          ? reports.data[reports.data.length - 1].createdAt.toDate()
          : dayjs(new Date()).subtract(1, 'day').hour(23).minute(59).second(59);
      setLastUpdated(dayjs(lastDocCreated).format('DD/MM/YYYY, HH:mm'));
      setLoading(false);
    };

    prepare();
  }, [routeOptions, timeframe]);

  const options: SelectOption[] = [
    {
      value: 'week',
      label: 'This week',
    },
    {
      value: 'month',
      label: 'This month',
    },
  ];

  const baseClasses = 'p-3 text-white body-02 rounded-lg flex items-center ';

  if (!dashboardData || !selectedRoute) {
    return <PageHeaderSkeleton actions={[]} />;
  }

  const sections = [
    {
      key: 'expected',
      value: dashboardData[selectedRoute.value].expectedDeliveries,
      title: (
        <div className={baseClasses.concat('bg-secondary')}>
          <Truck size={20} color='white' />
          <p className='ml-2'>Expected deliveries</p>
        </div>
      ),
    },
    {
      key: 'successful',
      value: dashboardData[selectedRoute.value].successfulDeliveries,
      title: (
        <div className={baseClasses.concat('bg-green-600')}>
          <CheckCircle size={20} color='white' />
          <p className='ml-2'>Successful deliveries</p>
        </div>
      ),
    },
    {
      key: 'incomplete',
      value: dashboardData[selectedRoute.value].incompleteDeliveries,
      title: (
        <div className={baseClasses.concat('bg-red-500')}>
          <XCircle size={20} color='white' />
          <p className='ml-2'>Incomplete deliveries</p>
        </div>
      ),
    },
  ];

  const doughnutData = {
    labels: ['Successful', 'Incomplete'],
    datasets: [
      {
        id: 1,
        label: 'Deliveries',
        data: [
          dashboardData[selectedRoute.value].successfulDeliveries,
          dashboardData[selectedRoute.value].incompleteDeliveries,
        ],
        backgroundColor: ['rgba(100, 163, 13, 1)', 'rgba(239, 68, 68, 1)'],
      },
    ],
  };

  return (
    <>
      <PageHeader title='Dashboard' />
      <div className='bg-white rounded-md shadow-md my-4'>
        <div className='flex items-center justify-between p-4 border-b'>
          <div className='flex items-center'>
            <p className='headline-05'>Time frame</p>
            <div className='ml-4 w-[200px]'>
              <Select<SelectOption> value={timeframe} options={options} onChange={(value) => setTimeframe(value!)} />
            </div>
            <p className='headline-05 ml-10'>Route</p>
            <div className='ml-4 w-[200px]'>
              <Select<SelectOption>
                value={selectedRoute}
                options={routeOptions}
                onChange={(value) => setSelectedRoute(value!)}
              />
            </div>
          </div>
          <p>{`Last updated: ${lastUpdated}`}</p>
        </div>
        <div className='grid grid-cols-3 gap-4'>
          {sections.map(({ key, title, value }) => (
            <div className='border-r last:border-0 py-4 pr-4 first:pl-4' key={key}>
              <div>{title}</div>
              {loading ? (
                <SkeletonElement height='60px' width='74px' className='mt-2' />
              ) : (
                <p className='text-6xl font-light pt-2'>{value}</p>
              )}
            </div>
          ))}
        </div>
        <div className='border-t p-12'>
          <div className='max-w-[25vw] mx-auto'>
            <Doughnut data={doughnutData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardView;
