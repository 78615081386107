import { LoadingButton } from '@atlaskit/button';
import { Color } from 'core/constants/colors';
import { ILoadingButton } from './loading-button-interface';

const SharedLoadingButton = ({
  fitContainer = false,
  type,
  appearance,
  isLoading,
  label,
  onClick,
  spacing,
  disabled = false,
}: ILoadingButton) => {
  const buttonClicked = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <LoadingButton
      shouldFitContainer={fitContainer}
      style={{ ...(appearance === 'primary' && { backgroundColor: Color.PRIMARY }) }}
      type={type}
      appearance={appearance}
      isLoading={isLoading}
      onClick={buttonClicked}
      spacing={spacing}
      isDisabled={disabled}
    >
      {label}
    </LoadingButton>
  );
};

export default SharedLoadingButton;
