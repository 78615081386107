import { GoogleMap, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IRoutePreviewDelivery } from '../route-preview-dialog/route-preview-dialog';
import PinDelivered from 'assets/images/map-pin-delivered.png';
import PinUnfulfilled from 'assets/images/map-pin-unfulfilled.png';
import PinScheduled from 'assets/images/map-pin-scheduled.png';
import { DeliveryStatus } from 'core/constants/delivery-status';

interface IRouteMap {
  routeDeliveries: IRoutePreviewDelivery[];
}

interface IDirectionService {
  complete: boolean;
  origin: {
    lng: number;
    lat: number;
  };
  waypoints: {
    location: {
      lat: number;
      lng: number;
    };
  }[];
  destination: {
    lat: number;
    lng: number;
  };
  result?: google.maps.DirectionsResult;
}

const RouteMap = ({ routeDeliveries }: IRouteMap) => {
  const [routeDirections, setRouteDirections] = useState<google.maps.DirectionsResult[]>([]);
  const hospital = useMemo(() => ({ lng: -1.51451023451506, lat: 52.911210709917015 }), []);

  const calculateDirections = useCallback(async (serviceArrays: IDirectionService[]) => {
    const directionsService = new google.maps.DirectionsService();
    const promises = serviceArrays.map((ds) => {
      return directionsService.route({
        origin: ds.origin,
        destination: ds.destination,
        waypoints: ds.waypoints,
        travelMode: google.maps.TravelMode.DRIVING,
      });
    });
    const results = await Promise.all(promises);
    setRouteDirections(results);
  }, []);

  useEffect(() => {
    const serviceArrays = [];
    let origin: {
      lng: number;
      lat: number;
    } = hospital;
    const allWaypoints = [
      ...routeDeliveries.map((delivery) => ({
        location: {
          lat: delivery.detail?.address.latitude!,
          lng: delivery.detail?.address.longitude!,
        },
      })),
      { location: hospital },
    ];
    while (allWaypoints.length) {
      const waypointsToAdd = allWaypoints.splice(0, 20);
      const end = waypointsToAdd.length - 1;
      const last = waypointsToAdd[waypointsToAdd.length - 1];
      serviceArrays.push({
        complete: false,
        origin,
        waypoints: waypointsToAdd.slice(0, end),
        destination: last.location,
      });
      origin = last.location;
    }

    calculateDirections(serviceArrays);
  }, [calculateDirections, hospital, routeDeliveries]);

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const getMarkerIcon = (deliveryStatus: string) => {
    switch (deliveryStatus) {
      case DeliveryStatus.DELIVERED:
        return PinDelivered;
      case DeliveryStatus.UNFULFILLED:
        return PinUnfulfilled;
      default:
        return PinScheduled;
    }
  };

  return (
    <GoogleMap mapContainerStyle={containerStyle} zoom={10}>
      {routeDirections.map((rd, index) => (
        <div key={`directionRenderer${index}`}>
          <DirectionsRenderer
            options={{
              suppressMarkers: true,
              directions: rd,
            }}
          />
        </div>
      ))}
      {routeDeliveries
        .filter((del) => del.detail)
        .map((del) => (
          <Marker
            key={del.key}
            label={{
              text: del.number.toString(),
              color: 'white',
            }}
            icon={{
              url: getMarkerIcon(del.detail!.status),
              labelOrigin: new google.maps.Point(18, 16),
            }}
            position={{
              lat: del.detail!.address.latitude,
              lng: del.detail!.address.longitude,
            }}
          />
        ))}
    </GoogleMap>
  );
};

export default RouteMap;
