import { Route, Routes } from 'react-router-dom';
import DriverMessagesList from './driver-messages-list/driver-messages-list';

const DriverMessagesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<DriverMessagesList />} />
    </Routes>
  );
};

export default DriverMessagesRouting;
