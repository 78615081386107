import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import { ButtonType } from 'core/enums/button-type';
import { ISharedDialogButton } from 'shared/components/dialog-base/dialog-base-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { auth } from 'core/config/firebase';
import { signOut } from 'firebase/auth';

const LogoutDialog = () => {
  // Hooks
  const dialog = useDialog();

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'subtle',
        label: 'Cancel',
        type: 'button',
      },
    },
    {
      key: 'logout',
      type: ButtonType.Button,
      buttonProps: {
        onClick: () => {
          dialog?.closeDialog();
          signOut(auth);
        },
        appearance: 'primary',
        label: 'Log Out',
        type: 'button',
      },
    },
  ];

  return (
    <SharedDialogBase title={`Log out?`} textContent='Are you sure you want to logout?' customButtons={customButtons} />
  );
};

export default LogoutDialog;
