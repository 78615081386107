import { Route, Routes } from 'react-router-dom';
import DashboardView from './dashboard-view/dashboard-view';

const DashboardRouting = () => {
  return (
    <Routes>
      <Route path='' element={<DashboardView />} />
    </Routes>
  );
};

export default DashboardRouting;
