import { useFlags } from '@atlaskit/flag';
import { IAddUserRequestDto } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { AddUserFormFields } from 'core/config/form-fields';
import { useDialog } from 'core/providers/DialogProvider';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { useState } from 'react';
import { IButton } from 'shared/components/buttons/button-interface';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SharedForm from 'shared/components/form/form';
import AddUserInfoDialog from '../add-user-info-dialog/add-user-info-dialog';
import { IAddUserFormOutput } from './add-user-dialog-interface';

const AddUserDialog = () => {
  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Hooks
  const flags = useFlags();
  const dialog = useDialog();

  // Page specifics
  const addUser = async (data: IAddUserFormOutput) => {
    setFormSubmitting(true);
    const payload: IAddUserRequestDto = {
      emailAddress: data.emailAddress,
      role: data.role.value,
      fullName: data.fullName,
    };
    try {
      const response = await UsersApiService.add(payload);
      dialog?.replaceDialog(
        <AddUserInfoDialog fullName={data.fullName} emailAddress={data.emailAddress} temporaryPassword={response} />
      );
    } catch (error) {
      showErrorFlag(
        'User creation failed',
        `Unable to create an account for ${data.fullName}, please try again.`,
        flags
      );
      setFormSubmitting(false);
    }
  };

  const cancelButton: IButton = {
    onClick: () => dialog?.closeDialog(),
    label: 'Cancel',
    appearance: 'subtle',
    type: 'button',
  };

  const customContent = () => {
    return (
      <SharedForm
        className='p-4'
        onSubmit={addUser}
        fields={AddUserFormFields}
        buttonLabel='Submit'
        loading={formSubmitting}
        cancelButton={cancelButton}
      />
    );
  };

  return <SharedDialogBase title='Add user' customContentTemplate={customContent()} showButtons={false} />;
};

export default AddUserDialog;
