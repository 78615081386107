import { useAuthState } from 'core/providers/AuthProvider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthenticatedRoute = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthState();

  if (!isAuthenticated) {
    return <Navigate to='login' state={{ from: location }} replace />;
  }
  return isAuthenticated ? <Outlet /> : <Navigate to='login' state={{ from: location }} replace />;
};

export default AuthenticatedRoute;
