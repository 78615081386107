import { useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import imageCompression from 'browser-image-compression';

interface IImageCapture {
  fieldProps: any;
}

const options = {
  maxSizeMB: 0.05,
  maxWidthOrHeight: 1920,
  useWebWorker: true
}

const ImageCapture = ({ fieldProps }: IImageCapture) => {
  const [selectedImage, setSelectedImage] = useState<string>();
  const reader = new FileReader();

  const imageChanged = async (event: any) => {
    const file: File = event.target.files[0];
    const compressedFile = await imageCompression(file, options);
    if (compressedFile) {
      const imageUrl = URL.createObjectURL(compressedFile);
      setSelectedImage(imageUrl);
      reader.onloadend = () => {
        fieldProps.onChange({
          valid: true,
          dataUrl: reader.result,
        });
      };
      reader.readAsDataURL(compressedFile);
    } else {
      setSelectedImage(undefined);
      fieldProps.onChange({
        valid: false,
      });
    }
  };

  return (
    <>
      {selectedImage && <img src={selectedImage} className='w-full mb-4' alt='' />}
      <div className='relative'>
        <SharedButton
          onClick={undefined}
          type='button'
          appearance='primary'
          label={selectedImage ? 'Change Image' : 'Select Image'}
        />
        <input
          className='w-full h-full absolute top-0 left-0 right-0 bottom-0 opacity-0'
          type='file'
          name='image'
          accept='image/*'
          onChange={(e) => imageChanged(e)}
        />
      </div>
    </>
  );
};

export default ImageCapture;
