import { useFlags } from '@atlaskit/flag';
import { IRoute } from 'core/api/routes/routes-api-interface';
import RoutesApiService from 'core/api/routes/routes-api.service';
import { getRouteOptionNameFromKey, RouteOptions } from 'core/constants/route-options';
import { showErrorFlag } from 'core/utilities/flags-helper';
import dayjs from 'dayjs';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableRow } from 'shared/components/table/table-interface';
import RouteTable from '../route-table/route-table';

const RoutesList = () => {
  // Local State
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState<ISharedTableRow<IRoute>[]>([]);

  // Hooks
  const flags = useFlags();
  const [searchParams] = useSearchParams();
  const route = searchParams.get('key');
  const navigate = useNavigate();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IRoute>) => {
      const rows: ISharedTableRow<IRoute>[] = [];
      querySnapshot.forEach((snap) => {
        const data = snap.data();
        rows.push({
          key: data.uid,
          data: {
            ...data,
            date: dayjs(data.date).format('DD/MM/YYYY'),
          },
        });
      });
      setTableRows(rows);
      setLoading(false);
    };

    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Could not retrieve routes, please try again.', flags);
    };

    if (!route || !RouteOptions.some((r) => r.value === route)) {
      navigate('/routes');
      return;
    }

    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = RoutesApiService.subscribeToRoutes(handleSnapshot, handleSubscriptionError, { routeKey: route }, [
      'date',
    ]);

    return () => {
      unsubscribe();
    };
  }, [flags, navigate, route]);

  return (
    <>
      <PageHeader title={`${getRouteOptionNameFromKey(route!)} routes`} showBack />
      <div className='mt-4'>
        <RouteTable tableRows={tableRows} loading={loading} routeType={route!} />
      </div>
    </>
  );
};

export default RoutesList;
