import { useFlags } from '@atlaskit/flag';
import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { IRoute } from 'core/api/routes/routes-api-interface';
import RoutesApiService from 'core/api/routes/routes-api.service';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { getRouteOptionFromKey, RouteOptions } from 'core/constants/route-options';
import { getSharpsBinOptionNameFromKey } from 'core/constants/sharps-bin-options';
import { getStorageRequirementNameFromKey } from 'core/constants/storage-requirement';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import { getToday } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { PageElement, SelectOption } from 'core/utilities/interface-helpers';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import RouteMap from 'modules/routes/route-map/route-map';
import { IRoutePreviewDelivery } from 'modules/routes/route-preview-dialog/route-preview-dialog';
import { useEffect, useMemo, useState } from 'react';
import { AlertCircle, Download, Edit3, LogOut, Map, RefreshCw, Trash2 } from 'react-feather';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedMobileHeader from 'shared/components/layout/mobile-header';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import LogoutDialog from '../logout-dialog/logout-dialog';
import Select from '@atlaskit/select';
import SharedButton from 'shared/components/buttons/button';
import { getSameDayRouteDurationStyle } from 'core/utilities/class-string-builder';

interface IDriverOverviewDelivery {
  delivery: IDelivery;
  number: number;
}

const DriverOverview = () => {
  const { userData } = useAuthState();
  // Local State
  const [loading, setLoading] = useState(true);
  const [routePreviewDeliveries, setRoutePreviewDeliveries] = useState<IRoutePreviewDelivery[]>([]);
  const [remainingDeliveries, setRemainingDeliveries] = useState<IDriverOverviewDelivery[]>();
  const [route, setRoute] = useState<IRoute>();
  const [showMap, setShowMap] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const routeParam = searchParams.get('route');
  const [selectedRoute, setSelectedRoute] = useState<string>();

  // Hooks
  const flags = useFlags();
  const navigate = useNavigate();
  const dialog = useDialog();

  const userRouteOptions = useMemo(
    () => RouteOptions.filter((option) => userData?.routes?.includes(option.value)),
    [userData?.routes]
  );

  useEffect(() => {
    const tabSelectionValid = userRouteOptions.some((r) => r.value === routeParam);
    if (tabSelectionValid && routeParam) {
      setSelectedRoute(routeParam);
    } else if (userData?.routes && userData.routes.length > 0) {
      const userRoutes = RouteOptions.filter((option) => userData?.routes?.includes(option.value));
      setSearchParams(`route=${userRoutes[0].value}`, { replace: true });
    }
  }, [routeParam, setSearchParams, userData?.routes, userRouteOptions]);

  useEffect(() => {
    if (selectedRoute) {
      let unsubscribe: Unsubscribe;
      const handleSnapshot = (querySnapshot: QuerySnapshot<IRoute>) => {
        if (querySnapshot.size === 0) {
          showErrorFlag('An error occurred', 'This route could not be loaded, they may have been removed.', flags);
          return;
        } else if (querySnapshot.size > 1) {
          showErrorFlag('An error occurred', 'There are multiple routes with the same key.', flags);
          return;
        } else {
          const route = querySnapshot.docs[0].data();
          setRoute(route);
        }
      };
      const handleSubscriptionError = (error: any) => {
        showErrorFlag('An error occurred', 'This route could not be loaded, they may have been removed.', flags);
      };
      unsubscribe = RoutesApiService.subscribeToRoutes(
        handleSnapshot,
        handleSubscriptionError,
        {
          routeKey: selectedRoute,
          date: getToday(),
        },
        [],
        1
      );
      return () => {
        unsubscribe();
      };
    }
  }, [flags, selectedRoute]);

  useEffect(() => {
    if (route && route.deliveries.length > 0) {
      let unsubscribe: Unsubscribe;
      const handleSnapshot = (querySnapshot: QuerySnapshot<IDelivery>) => {
        const deliveries: IDelivery[] = [];
        querySnapshot.forEach((snap) => {
          const data = snap.data();
          deliveries.push(data);
        });
        const allRoutePreviewDeliveries: IRoutePreviewDelivery[] = [];
        const requiredDeliveries = route.deliveries.reduce((result: IDriverOverviewDelivery[], rd) => {
          const delivery = deliveries.find((del) => del.uid === rd.deliveryUid);
          if (delivery && delivery.status === DeliveryStatus.SCHEDULED) {
            result.push({
              delivery,
              number: rd.number,
            });
          }

          if (delivery) {
            allRoutePreviewDeliveries.push({
              key: delivery.uid,
              number: rd.number,
              detail: delivery,
            });
          }
          return result;
        }, []);
        setRoutePreviewDeliveries(allRoutePreviewDeliveries);
        setRemainingDeliveries(requiredDeliveries);
        setLoading(false);
      };
      const handleSubscriptionError = (error: any) => {
        showErrorFlag('An error occurred', 'Could not retrieve your delivery route, please try again.', flags);
      };

      const queryOptions = {
        date: route.date,
      };
      unsubscribe = DeliveriesApiService.subscribeToAllDeliveries(
        handleSnapshot,
        handleSubscriptionError,
        queryOptions
      );

      return () => {
        unsubscribe();
      };
    } else {
      setLoading(false);
      setRemainingDeliveries([]);
      setRoutePreviewDeliveries([]);
    }
  }, [flags, route]);

  const headerActions: PageElement[] = [
    {
      key: 'download',
      element: (
        <button className='pl-2 py-2' onClick={() => navigate('app-download')}>
          <Download />
        </button>
      ),
    },
    {
      key: 'refresh',
      element: (
        <button className='pl-2 py-2' onClick={() => window.location.reload()}>
          <RefreshCw />
        </button>
      ),
    },
    {
      key: 'logout',
      element: (
        <button className='pl-2 py-2' onClick={() => dialog?.openDialog(<LogoutDialog />)}>
          <LogOut />
        </button>
      ),
    },
  ];

  return (
    <div className='bg-primary'>
      <SharedMobileHeader
        actions={headerActions}
        button={
          <SharedButton
            onClick={() => setShowMap(!showMap)}
            type='button'
            appearance='primary'
            label={showMap ? 'Hide route map' : 'Show route map'}
            spacing='none'
            fitContainer
          />
        }
      />

      <div className='p-4'>
        <p className='headline-04 text-white'>
          Hi {userData?.fullName}, <br />
        </p>
        <p className='body-01 opacity-80 text-white'>Here are today's remaining deliveries</p>
      </div>
      <div className='bg-white rounded-t-xl'>
        {loading || !remainingDeliveries || !route || !selectedRoute ? (
          <>
            <div className='flex justify-between items-center'>
              <div className='px-4 py-3'>
                <SkeletonElement width='190px' height='20px' />
                <SkeletonElement width='120px' height='18px' className='mt-2' />
              </div>
              <SkeletonElement width='56px' height='56px' className='my-4 mr-4' />
            </div>
          </>
        ) : (
          <>
            <div className='p-4 border-b'>
              <p className='label-02 font-semibold mb-1 text-gray-500'>Selected route</p>
              <Select<SelectOption>
                defaultValue={getRouteOptionFromKey(selectedRoute)}
                options={RouteOptions.filter((option) => userData?.routes?.includes(option.value))}
                onChange={(value) => {
                  if (value?.value) {
                    setSearchParams(`route=${value?.value}`, { replace: true });
                  }
                }}
                isClearable={false}
                isSearchable={false}
              />
            </div>
            {showMap && (
              <>
                <div className='p-4 body-02'>
                  <p style={getSameDayRouteDurationStyle(route, selectedRoute)}>
                    <span className='font-semibold'>Duration:</span> {route.duration}
                  </p>
                  <p>
                    <span className='font-semibold'>Mileage:</span> {route.mileage}
                  </p>
                </div>
                <RouteMap routeDeliveries={routePreviewDeliveries} />
              </>
            )}
            {remainingDeliveries.map(({ delivery, number }) => (
              <div key={delivery.uid} className='border-b flex justify-between items-center last:mb-[84px]'>
                <div className='p-4 w-full' onClick={() => navigate(delivery.uid, { state: { number } })}>
                  <p className='body-01 font-semibold'>
                    {delivery.address.line_1}, {delivery.address.town_or_city}, {delivery.address.postcode}
                  </p>
                  <p className='body-02 mt-1'>
                    {`${delivery.parcelCount ?? 1} ${delivery.parcelCount !== '1' ? 'parcels' : 'parcel'} for ${
                      delivery.firstName
                    } ${delivery.lastName}`}
                  </p>
                  {delivery.storageRequirement !== '' && (
                    <p className='body-03 mt-1'>{`${getStorageRequirementNameFromKey(
                      delivery.storageRequirement
                    )} storage`}</p>
                  )}
                  {delivery.sharpsBin !== 'no' && delivery.sharpsBin !== '' && (
                    <div className='inline-block'>
                      <div
                        className={`flex py-1 px-2 rounded-3xl ${
                          delivery.sharpsBin === 'cyto' ? 'bg-purple-100' : 'bg-yellow-100'
                        } body-03 items-center mt-2 mr-2`}
                      >
                        <Trash2 size={16} />
                        <p className='ml-2'>{getSharpsBinOptionNameFromKey(delivery.sharpsBin)} sharps bin required</p>
                      </div>
                    </div>
                  )}
                  {delivery.wasteCollection === 'yes' && (
                    <div className='inline-block'>
                      <div className='flex py-1 px-2 rounded-3xl bg-red-100 body-03 items-center mt-2 mr-2'>
                        <AlertCircle size={16} color='red' />
                        <p className='ml-2'>Waste collection required</p>
                      </div>
                    </div>
                  )}
                  {delivery.notes !== '' && (
                    <div className='inline-block'>
                      <div className='flex py-1 px-2 rounded-3xl bg-blue-100 body-03 items-center mt-2'>
                        <Edit3 size={16} />
                        <p className='ml-2'>Dispenser note</p>
                      </div>
                    </div>
                  )}
                </div>
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${delivery.address.latitude},${delivery.address.longitude}`}
                >
                  <div className='rounded-full p-4 my-4 mr-4 bg-secondary'>
                    <Map color='white' />
                  </div>
                </a>
              </div>
            ))}
            {remainingDeliveries.length === 0 && (
              <div className='px-4 py-6 w-full border-b'>
                <p>No deliveries remaining</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DriverOverview;
