import { isNotNullOrEmpty } from 'core/utilities/null-checkers';

export interface IDeliveryOverviewNotes {
  dispenserNote: string;
  driverNote: string;
}

const DeliveryOverviewNotes = ({ dispenserNote, driverNote }: IDeliveryOverviewNotes) => {
  const noteSections = [
    {
      key: 'dispenser',
      title: 'Dispenser note',
      content: isNotNullOrEmpty(dispenserNote) ? dispenserNote : 'No notes added by dispenser',
      entered: isNotNullOrEmpty(dispenserNote),
    },
    ...(driverNote
      ? [
          {
            key: 'driver',
            title: 'Driver note',
            content: isNotNullOrEmpty(driverNote) ? driverNote : 'No notes added by driver',
            entered: isNotNullOrEmpty(driverNote),
          },
        ]
      : []),
  ];
  return (
    <div className='bg-white rounded-md shadow-md'>
      <div className='flex justify-between items-center p-4 border-b'>
        <p className='headline-06'>Delivery notes</p>
      </div>
      {noteSections.map((section) => (
        <div key={section.key} className='p-4 border-b last:border-0'>
          <p className='body-02 text-gray-500'>{section.title}</p>
          <p className={`body-01 break-words whitespace-pre-wrap ${!section.entered && 'text-stone-300'}`}>
            {section.content}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DeliveryOverviewNotes;
