import { IRoute } from 'core/api/routes/routes-api-interface';
import { useDialog } from 'core/providers/DialogProvider';
import { getSameDayRouteDurationStyle } from 'core/utilities/class-string-builder';
import SharedButton from 'shared/components/buttons/button';
import { ILoadingButton } from 'shared/components/buttons/loading-button-interface';
import SharedTable from 'shared/components/table/table';
import {
  ISharedTableColumn,
  ISharedTableCustomCellTemplate,
  ISharedTableRow,
} from 'shared/components/table/table-interface';
import RoutePreviewDialog from '../route-preview-dialog/route-preview-dialog';
import dayjs from 'dayjs';

const RoutesTableColumns: ISharedTableColumn[] = [
  { label: 'Route Date', key: 'date', templateId: 'date', width: 40 },
  { label: 'Total Deliveries', key: 'count', templateId: 'text', width: 10 },
  { label: 'Duration', key: 'duration', templateId: 'duration', width: 30 },
  { label: 'Mileage', key: 'mileage', templateId: 'text', width: 10 },
  { label: '', key: 'action', templateId: 'action', width: 10, isCompact: true },
];

interface IRouteTable {
  tableRows: ISharedTableRow<IRoute>[];
  loading?: boolean;
  loadMoreButton?: ILoadingButton;
  routeType: string;
}

const RouteTable = ({ tableRows, loading, loadMoreButton, routeType }: IRouteTable) => {
  const dialog = useDialog();

  const actionCellTemplate = (route: IRoute) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton onClick={() => showRoutePreviewDialog(route)} type='button' appearance='link' label='View' />
      </div>
    );
  };

  const dateCellTemplate = (route: IRoute) => {
    return (
      <div className='flex items-center'>
        <p>{route.date}</p>
        <div>{dayjs(new Date()).format('DD/MM/YYYY') === route.date && <p className='ml-2 text-xs p-1 px-2 bg-primary text-white rounded-full'>Today</p>}</div>
      </div>
    );
  };

  const durationCellTemplate = (route: IRoute) => {
    return <p style={getSameDayRouteDurationStyle(route, routeType)}>{route.duration ?? 'Unknown'}</p>;
  };

  const showRoutePreviewDialog = (route: IRoute) => {
    dialog?.openDialog(<RoutePreviewDialog route={route} routeType={routeType} />);
  };

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: dateCellTemplate,
      id: 'date',
    },
    {
      template: actionCellTemplate,
      id: 'action',
    },
    {
      template: durationCellTemplate,
      id: 'duration',
    },
  ];

  return (
    <SharedTable
      columns={RoutesTableColumns}
      rows={tableRows}
      isLoading={loading}
      customTemplates={customTemplates}
      loadMoreButton={loadMoreButton}
    />
  );
};

export default RouteTable;
