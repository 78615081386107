import { SidebarOptions } from 'core/config/sidebar-options';
import { useAuthState } from 'core/providers/AuthProvider';
import SharedSidebarItem from './sidebar-item';
import { useDriverMessagesState } from 'core/providers/DriverMessagesProvider';

const SharedSidebar = () => {
  const { userRole } = useAuthState();
  const { unreadMessages } = useDriverMessagesState();
  const badges: { [key: string]: number | undefined } = {
    'driver-messages': unreadMessages.length > 0 ? unreadMessages.length : undefined,
  };
  return (
    <div className='border-r flex-grow-0 flex-shrink-0 basis-[288px]'>
      {SidebarOptions.filter((item) => item.roles.includes(userRole!)).map((item) => (
        <SharedSidebarItem
          key={item.route}
          icon={item.icon}
          label={item.label}
          route={item.route}
          badgeCount={badges[item.route]}
        />
      ))}
    </div>
  );
};

export default SharedSidebar;
