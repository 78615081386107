export const WasteCollection = {
  YES: 'yes',
  NO: 'no',
};

export const WasteCollectionOptions: IWasteCollection[] = [
  {
    label: 'Yes',
    key: WasteCollection.YES,
  },
  {
    label: 'No',
    key: WasteCollection.NO,
  },
];

export const getWasteCollectionFromKey = (key: string) => {
  const wc = WasteCollectionOptions.find((wc) => wc.key === key);
  return wc;
};

export const getWasteCollectionNameFromKey = (key: string) => {
  const wc = WasteCollectionOptions.find((wc) => wc.key === key);
  return wc?.label ?? 'Unknown';
};

export interface IWasteCollection {
  label: string;
  key: string;
}
