import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { auth } from 'core/config/firebase';
import { Role } from 'core/config/roles';
import { onAuthStateChanged, User } from 'firebase/auth';
import { Unsubscribe } from 'firebase/firestore';
import { createContext, useContext, useEffect, useState } from 'react';

interface IAuthContext {
  user: User | null;
  error?: Error;
  authChecked: boolean;
  userRole?: Role;
  userData?: IUser;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const useAuthState = () => {
  const state = useContext(AuthContext);
  return { ...state, isAuthenticated: state?.user !== null };
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<IUser>();
  const [userRole, setUserRole] = useState<Role>();
  const [error, setError] = useState<Error>();
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  useEffect(() => {
    const handleCurrentUserDocChanges = (doc: IUser) => {
      setUserData(doc);
    };

    const handleSubscriptionError = (error: any) => {
      console.log(error);
    };

    let unsubscribeFromUserDoc: Unsubscribe;

    const unsubscribeFromAuth = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          try {
            unsubscribeFromUserDoc = UsersApiService.subscribeToUser(
              user.uid,
              handleCurrentUserDocChanges,
              handleSubscriptionError
            );
            const idTokenResult = await user.getIdTokenResult();
            const roleKey = idTokenResult.claims.role as Role;
            setUserRole(roleKey);
          } catch (error) {
            setError(new Error('Failed to process user data.'));
          }
        }
        setUser(user);
        setTimeout(() => {
          setAuthChecked(true);
        }, 1000);
      },
      setError
    );

    return () => {
      unsubscribeFromAuth();

      if (unsubscribeFromUserDoc) {
        unsubscribeFromUserDoc();
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, error, authChecked, userRole, userData }}>{children}</AuthContext.Provider>
  );
};
