import SharedHeader from './header';
import SharedSidebar from '../sidebar/sidebar';

const AdminLayout = ({ children }: any) => {
  return (
    <div className='h-screen bg-gray-50'>
      <SharedHeader title='Pride Pharmacy - Delivery Portal' />
      <div className='h-full pt-[64px] flex'>
        <SharedSidebar />
        <div className='overflow-y-auto flex-grow px-6 pb-6 flex flex-col'>{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
