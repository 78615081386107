import { IUser } from 'core/api/users/users-api-interface';
import UsersApiService from 'core/api/users/users-api.service';
import { getRoleNameFromKey } from 'core/config/roles';
import { useDialog } from 'core/providers/DialogProvider';
import { PageElement } from 'core/utilities/interface-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import SharedButton from 'shared/components/buttons/button';
import PageHeader from 'shared/components/page-header/page-header';
import SharedTable from 'shared/components/table/table';
import {
  ISharedTableColumn,
  ISharedTableCustomCellTemplate,
  ISharedTableRow,
} from 'shared/components/table/table-interface';
import DeleteUserDialog from '../delete-user-dialog/delete-user-dialog';
import { Unsubscribe } from 'firebase/firestore';
import { useFlags } from '@atlaskit/flag';
import { showErrorFlag } from 'core/utilities/flags-helper';
import AddUserDialog from '../add-user/add-user-dialog/add-user-dialog';
import { IUsersListRow } from './users-list-interface';
import DriverRoutesDialog from '../driver-routes-dialog/driver-routes-dialog';

const UsersTableColumns: ISharedTableColumn[] = [
  { label: 'User', key: 'user', templateId: 'user', width: 45 },
  { label: 'Role', key: 'role', templateId: 'text', width: 45 },
  { label: '', key: 'action', templateId: 'action', width: 10 },
];

const UsersList = () => {
  // Local State
  const [loading, setLoading] = useState(false);
  const [tableRows, setTableRows] = useState<ISharedTableRow<IUsersListRow>[]>([]);

  // Hooks
  const dialog = useDialog();
  const flags = useFlags();

  useEffect(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IUser>) => {
      const userRows: ISharedTableRow<IUsersListRow>[] = [];
      querySnapshot.forEach((userSnapShot) => {
        const userData = userSnapShot.data();
        const rowData = {
          key: userData.uid,
          data: {
            ...userData,
            role: getRoleNameFromKey(userData.role),
          },
        };
        userRows.push(rowData);
      });
      setTableRows(userRows);
      setLoading(false);
    };

    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Could not retrieve users, please try again.', flags);
    };

    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = UsersApiService.subscribeToAllUsers(handleSnapshot, handleSubscriptionError);

    return () => {
      unsubscribe();
    };
  }, [flags]);

  // Page specifics
  const userCellTemplate = (user: IUser) => {
    return (
      <div className='flex'>
        <div className='h-10 w-10 rounded-full bg-primary mr-3 flex items-center justify-center text-white body-01'>
          {user.fullName.charAt(0).toUpperCase()}
        </div>
        <div className='flex flex-col'>
          <p>{user.fullName}</p>
          <p className='text-gray-400'>{user.emailAddress}</p>
        </div>
      </div>
    );
  };
  const actionCellTemplate = (user: IUser) => (
    <div className='w-full flex justify-end space-x-2'>
      {user.role === 'Driver' && (
        <SharedButton
          onClick={() => dialog?.openDialog(<DriverRoutesDialog user={user} />)}
          type='button'
          appearance='link'
          label='Select routes'
        />
      )}
      <SharedButton
        onClick={() => dialog?.openDialog(<DeleteUserDialog user={user} />)}
        type='button'
        appearance='link'
        label='Delete'
      />
    </div>
  );

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: userCellTemplate,
      id: 'user',
    },
    {
      template: actionCellTemplate,
      id: 'action',
    },
  ];
  const headerActions: PageElement[] = [
    {
      key: 'addUser',
      element: (
        <SharedButton
          onClick={() => dialog?.openDialog(<AddUserDialog />)}
          type='button'
          appearance='primary'
          label='Add User'
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader title='Users' actions={headerActions} />
      <div className='mt-4'>
        <SharedTable
          columns={UsersTableColumns}
          customTemplates={customTemplates}
          rows={tableRows}
          isLoading={loading}
        />
      </div>
    </>
  );
};

export default UsersList;
