import { PageElement } from 'core/utilities/interface-helpers';
import { ArrowLeftCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

interface IPageHeader {
  title: string;
  actions?: PageElement[];
  subtitle?: string;
  showBack?: boolean;
}

const PageHeader = ({ title, actions, subtitle, showBack = false }: IPageHeader) => {
  const navigate = useNavigate();
  return (
    <div className='flex-grow-0 flex-shrink-0 basis-[88px] flex items-center justify-between border-b'>
      <div className='py-6'>
        {showBack && (
          <div className='flex items-center text-gray-600 mb-4 cursor-pointer' onClick={() => navigate(-1)}>
            <ArrowLeftCircle size={20} />
            <p className='body-02 ml-2 font-semibold'>Go back</p>
          </div>
        )}
        <p className='headline-03'>{title}</p>
        {subtitle && <p className='body-01 text-gray-500 mt-2'>{subtitle}</p>}
      </div>
      <div className='flex'>
        {actions?.map((action) => (
          <div className='mr-2 last:mr-0' key={action.key}>
            {action.element}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageHeader;
