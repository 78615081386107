import { DeliveriesProvider } from 'core/providers/DeliveriesProvider';
import { Route, Routes } from 'react-router-dom';
import DeliveriesList from './deliveries-list/deliveries-list';
import DeliveryOverview from './delivery-overview/delivery-overview';

const DeliveriesRouting = () => {
  return (
    <Routes>
      <Route element={<DeliveriesProvider />}>
        <Route path='' element={<DeliveriesList />} />
        <Route path=':uid' element={<DeliveryOverview />} />
      </Route>
    </Routes>
  );
};

export default DeliveriesRouting;
