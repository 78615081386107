import { PageElement } from 'core/utilities/interface-helpers';
import { ReactElement } from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

interface ISharedMobileHeader {
  title?: string;
  backButton?: boolean;
  actions?: PageElement[];
  button?: ReactElement;
}

const SharedMobileHeader = ({ title, backButton, actions, button }: ISharedMobileHeader) => {
  const navigate = useNavigate();

  return (
    <div className='w-full h-[64px] bg-primary flex items-center px-4 text-white justify-between'>
      <div>
        {button && button}
        {backButton && (
          <button className='p-4 -ml-4' onClick={() => navigate(-1)}>
            <ArrowLeft color='white' />
          </button>
        )}
        {title && <p className='text-white headline-06'>{title}</p>}
      </div>
      <div className='flex -mr-2'>
        {actions?.map((action) => (
          <div key={action.key} className='mr-3 last:mr-0 p-2'>
            {action.element}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedMobileHeader;
