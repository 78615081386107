export const TimeWindow = {
  AM: 'AM',
  PM: 'PM',
  ANY: 'Any',
};

export const TimeWindows = [
  {
    label: 'Any',
    value: TimeWindow.ANY,
  },
  {
    label: 'AM',
    value: TimeWindow.AM,
  },
  {
    label: 'PM',
    value: TimeWindow.PM,
  },
];

export const getTimeWindowFromKey = (key: string) => {
  const timeWindow = TimeWindows.find((timeWindow) => timeWindow.value === key);
  return timeWindow;
};

export const getTimeWindowNameFromKey = (key: string) => {
  const timeWindow = TimeWindows.find((timeWindow) => timeWindow.value === key);
  return timeWindow?.label ?? 'Unknown';
};
