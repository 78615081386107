export const DeliveryStatus = {
  SCHEDULED: 'scheduled',
  UNFULFILLED: 'unfulfilled',
  DELIVERED: 'delivered',
};

export const DeliveryStatuses: IDeliveryStatus[] = [
  {
    label: 'Scheduled',
    key: DeliveryStatus.SCHEDULED,
  },
  {
    label: 'Unfulfilled',
    key: DeliveryStatus.UNFULFILLED,
  },
  {
    label: 'Delivered',
    key: DeliveryStatus.DELIVERED,
  },
];

export const getDeliveryStatusFromKey = (key: string) => {
  const status = DeliveryStatuses.find((status) => status.key === key);
  return status;
};

export const getDeliveryStatusNameFromKey = (key: string) => {
  const status = DeliveryStatuses.find((status) => status.key === key);
  return status?.label ?? 'Unknown';
};

export interface IDeliveryStatus {
  label: string;
  key: string;
}
