import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { IRoute } from 'core/api/routes/routes-api-interface';
import { DeliveryStatus } from 'core/constants/delivery-status';
import { useDialog } from 'core/providers/DialogProvider';
import { useEffect, useState } from 'react';
import { CheckCircle, Trash, Truck, XCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import SharedDialogBase from 'shared/components/dialog-base/dialog-base';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import { getSameDayRouteDurationStyle } from 'core/utilities/class-string-builder';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import RouteMap from '../route-map/route-map';

interface IRoutePreviewDialog {
  route: IRoute;
  routeType: string;
}

export interface IRoutePreviewDelivery {
  key: string;
  number: number;
  detail?: IDelivery;
}

const RoutePreviewDialog = ({ route, routeType }: IRoutePreviewDialog) => {
  const [loading, setLoading] = useState(true);
  const [routeDeliveries, setRouteDeliveries] = useState<IRoutePreviewDelivery[]>();
  const [validDeliveries, setValidDeliveries] = useState<IRoutePreviewDelivery[]>();
  const navigate = useNavigate();
  const dialog = useDialog();

  useEffect(() => {
    const prepare = async () => {
      const promises: Promise<IRoutePreviewDelivery>[] = route.deliveries.map(async (routeDelivery) => {
        try {
          const delivery = await DeliveriesApiService.get(routeDelivery.deliveryUid);
          return {
            key: routeDelivery.deliveryUid,
            number: routeDelivery.number,
            detail: delivery.data,
          };
        } catch (error) {
          return {
            key: routeDelivery.deliveryUid,
            number: routeDelivery.number,
          };
        }
      });
      const routeDeliveries = await Promise.all(promises);
      let nonDeletedIndex = 0;
      const correctlyIndexedRouteDeliveries = routeDeliveries.map((del) => {
        if (del.detail) {
          nonDeletedIndex += 1;
          return {
            ...del,
            number: nonDeletedIndex,
          };
        }
        return del;
      });
      setRouteDeliveries(correctlyIndexedRouteDeliveries);
      setValidDeliveries(correctlyIndexedRouteDeliveries.filter((delivery) => delivery.detail));
      setLoading(false);
    };
    prepare();
  }, [route.deliveries]);

  const getIcon = (number: number, type: string, delivery?: IDelivery) => {
    const baseClasses = 'p-2 mr-3 rounded-lg flex items-center ';
    const numberDiv = <p className='text-white body-01 font-semibold ml-2'>{number}</p>;
    if (type === 'deleted') {
      return (
        <div className={baseClasses.concat('bg-gray-300 h-[40px] w-[55px] justify-center')}>
          <Trash size={20} color='white' />
        </div>
      );
    }

    if (type === 'hospital') {
      return (
        <div className={baseClasses.concat('bg-blue-300')}>
          <OfficeBuildingIcon label='' primaryColor='white' />
        </div>
      );
    }

    if (!delivery) {
      return;
    }

    switch (delivery.status) {
      case DeliveryStatus.SCHEDULED:
        return (
          <div className={baseClasses.concat('bg-secondary')}>
            <Truck size={20} color='white' />
            {numberDiv}
          </div>
        );
      case DeliveryStatus.UNFULFILLED:
        return (
          <div className={baseClasses.concat('bg-red-500')}>
            <XCircle size={20} color='white' />
            {numberDiv}
          </div>
        );
      case DeliveryStatus.DELIVERED:
        return (
          <div className={baseClasses.concat('bg-green-600')}>
            <CheckCircle size={20} color='white' />
            {numberDiv}
          </div>
        );
    }
  };

  const viewDelivery = (uid: string) => {
    navigate(`/deliveries/${uid}`);
    dialog?.closeDialog();
  };

  const hospitalEntry = (index: number, label: string) => (
    <div className='p-4 border-b last:border-0 flex items-center'>
      {getIcon(index, 'hospital')}
      <div>
        <p className='body-01 font-semibold'>{label}</p>
      </div>
    </div>
  );

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        {loading || !routeDeliveries || !validDeliveries ? (
          <div className='p-4 border-b last:border-0 flex items-center'>
            <SkeletonElement width='50px' height='46px' className='mr-3' />
            <div>
              <SkeletonElement width='250px' height='16px' className='mb-2' />
              <SkeletonElement width='360px' height='22px' />
            </div>
          </div>
        ) : (
          <>
            <div className='grid grid-cols-2 p-4 body-02'>
              <p style={getSameDayRouteDurationStyle(route, routeType)}>
                <span className='font-semibold'>Duration:</span> {route.duration ?? 'Unknown'}
              </p>
              <p>
                <span className='font-semibold'>Mileage:</span> {route.mileage ?? 'Unknown'}
              </p>
            </div>
            <RouteMap routeDeliveries={validDeliveries} />
            {routeDeliveries.length === 0 ? (
              <p className='p-4 body-02 text-gray-500'>No deliveries scheduled</p>
            ) : (
              <>
                {hospitalEntry(0, 'Start')}
                {routeDeliveries.map(({ detail, key, number }) =>
                  !detail ? (
                    <div key={key} className='p-4 border-b last:border-0 flex items-center'>
                      {getIcon(number, 'deleted')}
                      <p className='body-01 text-gray-300'>Delivery deleted</p>
                    </div>
                  ) : (
                    <div
                      key={key}
                      className='p-4 border-b last:border-0 flex items-center cursor-pointer hover:bg-gray-50'
                      onClick={() => (detail ? viewDelivery(key) : {})}
                    >
                      {getIcon(number, 'delivery', detail)}
                      <div>
                        <p className='body-02'>
                          {detail.firstName} {detail.lastName}
                        </p>
                        <p className='body-01 font-semibold'>
                          {detail.address.line_1}, {detail.address.town_or_city}, {detail.address.postcode}
                        </p>
                      </div>
                    </div>
                  )
                )}
                {hospitalEntry(validDeliveries.length + 1, 'End')}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return <SharedDialogBase title={`Route for ${route.date}`} customContentTemplate={customContent()} />;
};

export default RoutePreviewDialog;
