import { IDriverMessage } from 'core/api/driver-messages/driver-messages-api-interface';
import DriverMessagesApiService from 'core/api/driver-messages/driver-messages-api.service';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDriverMessagesState } from 'core/providers/DriverMessagesProvider';
import { StringIndexable } from 'core/utilities/interface-helpers';
import dayjs from 'dayjs';
import { useState } from 'react';
import SharedLoadingButton from 'shared/components/buttons/loading-button';
import PageHeader from 'shared/components/page-header/page-header';

const DriverMessagesList = () => {
  const { messages } = useDriverMessagesState();
  const [messagesUpdating, setMessagesUpdating] = useState<StringIndexable>({});
  const { userData } = useAuthState();

  const markAsRead = async (message: IDriverMessage) => {
    setMessagesUpdating((prevState) => ({ ...prevState, [message.uid]: true }));
    await DriverMessagesApiService.update(message.uid, {
      isRead: true,
      read: {
        by: {
          uid: userData?.uid!,
          fullName: userData?.fullName!,
        },
        at: new Date(),
      },
    });
    setMessagesUpdating((prevState) => ({ ...prevState, [message.uid]: false }));
  };

  return (
    <>
      <PageHeader title='Driver messages' />
      <div className='bg-white w-full rounded-md shadow-md overflow-x-auto mt-4'>
        {messages.map((message) => (
          <div key={message.uid} className='border-b last:border-0 body-02 flex relative'>
            <div className={`w-[5px] min-h-full ${message.isRead ? 'bg-white' : 'bg-red-500'}`} />
            <div className='w-full p-4'>
              <div className='flex justify-between'>
                <p className='mb-2'>
                  <b>From: </b>
                  {message.author.fullName}, {dayjs(message.sentAt.toDate()).format('DD/MM/YYYY, HH:mm')}
                </p>
                {message.isRead ? (
                  <p>
                    <b>Read: </b>
                    {dayjs(message.read?.at.toDate()).format('DD/MM/YYYY, HH:mm')} by {message.read?.by.fullName}
                  </p>
                ) : (
                  <SharedLoadingButton
                    type='button'
                    appearance='link'
                    spacing='none'
                    label='Mark as read'
                    isLoading={messagesUpdating[message.uid]}
                    onClick={() => markAsRead(message)}
                  />
                )}
              </div>
              <p className='body-01'>{message.content}</p>
            </div>
          </div>
        ))}
        {messages.length === 0 && <p className='p-4 body-02'>No messages</p>}
      </div>
    </>
  );
};

export default DriverMessagesList;
