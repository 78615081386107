import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return dayjs(tomorrow).format('YYYY-MM-DD');
};

export const getToday = () => {
  const today = new Date();
  return dayjs(today).format('YYYY-MM-DD');
};
