import { IRouteRoles } from 'core/config/roles';
import { useAuthState } from 'core/providers/AuthProvider';
import { useDialog } from 'core/providers/DialogProvider';
import SetPasswordDialog from 'modules/auth/set-password-dialog/set-password-dialog';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AdminLayout from 'shared/components/layout/admin-layout';

const RoleHandler = ({ roles }: IRouteRoles) => {
  const location = useLocation();
  const { userRole, userData } = useAuthState();

  const dialog = useDialog();

  useEffect(() => {
    if (userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
    }
  }, [dialog, userData?.resetPassword]);

  if (roles && !roles.includes(userRole!)) {
    return <Navigate to='' state={{ from: location }} replace />;
  }

  if (userRole === 'driver') {
    return <Outlet />;
  }

  return (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );
};

export default RoleHandler;
