import { useFlags } from '@atlaskit/flag';
import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { getToday, getTomorrow } from 'core/utilities/date-helpers';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

interface IDeliveriesContext {
  tomorrowsDeliveryCount: number;
  todaysDeliveryCount: number;
}

export const DeliveriesContext = createContext<IDeliveriesContext | null>(null);

export const useDeliveriesState = () => {
  const state = useContext(DeliveriesContext);
  return { ...state };
};

export const DeliveriesProvider = ({ children }: any) => {
  const [tomorrowsDeliveryCount, setTomorrowsDeliveryCount] = useState(0);
  const [todaysDeliveryCount, setTodaysDeliveryCount] = useState(0);

  const flags = useFlags();

  useEffect(() => {
    const handleSnapshot = (stateAction: Dispatch<SetStateAction<number>>, querySnapshot: QuerySnapshot<IDelivery>) => {
      stateAction(querySnapshot.docs.length);
    };

    const handleSubscriptionError = (error: any) => {
      showErrorFlag('An error occurred', 'Could not retrieve delivery counts', flags);
    };

    let unsubscribeFromTomorrowsDeliveries: Unsubscribe;
    let unsubscribeFromTodaysDeliveries: Unsubscribe;
    unsubscribeFromTomorrowsDeliveries = DeliveriesApiService.subscribeToAllDeliveries(
      (querySnapshot: QuerySnapshot<IDelivery>) => handleSnapshot(setTomorrowsDeliveryCount, querySnapshot),
      handleSubscriptionError,
      { date: getTomorrow() }
    );

    unsubscribeFromTodaysDeliveries = DeliveriesApiService.subscribeToAllDeliveries(
      (querySnapshot: QuerySnapshot<IDelivery>) => handleSnapshot(setTodaysDeliveryCount, querySnapshot),
      handleSubscriptionError,
      { date: getToday() }
    );

    return () => {
      unsubscribeFromTomorrowsDeliveries();
      unsubscribeFromTodaysDeliveries();
    };
  }, [flags]);

  return (
    <DeliveriesContext.Provider value={{ tomorrowsDeliveryCount, todaysDeliveryCount }}>
      <Outlet />
    </DeliveriesContext.Provider>
  );
};
