import { useFlags } from '@atlaskit/flag';
import { IDelivery } from 'core/api/deliveries/deliveries-api-interface';
import DeliveriesApiService from 'core/api/deliveries/deliveries-api.service';
import { getDeliveryTypeNameFromKey } from 'core/constants/delivery-types';
import { getStorageRequirementNameFromKey } from 'core/constants/storage-requirement';
import { getWasteCollectionNameFromKey } from 'core/constants/waste-collection';
import { useDialog } from 'core/providers/DialogProvider';
import { createAddressString } from 'core/utilities/address-string-builder';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';
import { Unsubscribe } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SharedMobileHeader from 'shared/components/layout/mobile-header';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import FailedDeliveryDialog from '../failed-delivery-dialog/failed-delivery-dialog';

const DriverDelivery = () => {
  // Local state
  const [loading, setLoading] = useState(true);
  const [delivery, setDelivery] = useState<IDelivery>();

  // Hooks
  const { uid } = useParams();
  const flags = useFlags();
  const dialog = useDialog();
  const { state } = useLocation();
  const { number } = state as { number: number };
  const navigate = useNavigate();

  useEffect(() => {
    if (uid) {
      const handleSnapshot = (doc: IDelivery) => {
        setDelivery(doc);
        setLoading(false);
      };
      const handleSubscriptionError = (error: any) => {
        showErrorFlag('An error occurred', 'This customer could not be loaded, they may have been removed.', flags);
      };
      let unsubscribe: Unsubscribe;
      unsubscribe = DeliveriesApiService.subscribeToDelivery(uid, handleSnapshot, handleSubscriptionError);
      return () => {
        unsubscribe();
      };
    }
  }, [flags, uid]);

  const sectionsContent = (delivery: IDelivery) => {
    const { address, firstName, lastName, type, phoneNumber, notes, parcelCount, wasteCollection, storageRequirement } =
      delivery;
    return [
      {
        title: 'Address',
        content: createAddressString([...address.formatted_address, address.postcode]),
        key: 'address',
      },
      {
        title: 'Customer name',
        content: `${firstName} ${lastName}`,
        key: 'name',
      },
      {
        title: 'Number of parcels',
        content: parcelCount ?? 1,
        key: 'parcelCount',
      },
      ...(isNotNullOrEmpty(wasteCollection)
        ? [
            {
              title: 'Waste collection required',
              content: getWasteCollectionNameFromKey(wasteCollection),
              key: 'wasteCollection',
            },
          ]
        : []),
      ...(isNotNullOrEmpty(storageRequirement)
        ? [
            {
              title: 'Storage requirement',
              content: getStorageRequirementNameFromKey(storageRequirement),
              key: 'storageRequirement',
            },
          ]
        : []),
      {
        title: 'Customer phone number',
        content: phoneNumber,
        key: 'phoneNumber',
      },
      ...(isNotNullOrEmpty(type)
        ? [
            {
              title: 'Delivery type',
              content: getDeliveryTypeNameFromKey(type),
              key: 'type',
            },
          ]
        : []),
      ...(isNotNullOrEmpty(notes)
        ? [
            {
              title: 'Notes',
              content: notes,
              key: 'notes',
            },
          ]
        : []),
    ];
  };

  const section = (title: string, content: string | number, key: string) => (
    <div key={key} className='border-b p-4'>
      <p className='body-02 font-semibold text-gray-400'>{title}</p>
      <p> {content}</p>
    </div>
  );

  const failedDelivery = () => {
    dialog?.openDialog(<FailedDeliveryDialog delivery={delivery!} />);
  };

  const deliveryComplete = () => {
    navigate('signature');
  };

  return (
    <div className='bg-primary'>
      <SharedMobileHeader backButton />

      <div className='bg-white rounded-t-2xl'>
        {loading || !delivery ? (
          <div className='px-4 py-6'>
            <SkeletonElement width='150px' height='36px' />
          </div>
        ) : (
          <>
            <div className='px-4 py-6 border-b'>
              <p className='headline-04'>Delivery #{number}</p>
            </div>
            <div className='pb-[85px]'>
              {sectionsContent(delivery).map((contentSection) =>
                section(contentSection.title, contentSection.content, contentSection.key)
              )}
            </div>
            <div className='fixed bottom-0 h-[86px] p-4 bg-white w-full border-t grid grid-cols-2 gap-4'>
              <button
                className='w-full h-full bg-red-500 text-white body-02 rounded-md'
                onClick={() => failedDelivery()}
              >
                Not delivered/collected
              </button>
              <button
                className='w-full h-full bg-green-600 text-white body-02 rounded-md'
                onClick={() => deliveryComplete()}
              >
                Delivered/Collected
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DriverDelivery;
