export const DeliveryRouteAssignment = {
  NONE: 'none',
  REGULAR: 'regular',
  HOSPITAL: 'hospital',
  SAME_DAY_EVENING: 'sameDayEvening',
};

export const DeliveryRouteAssignments = [
  {
    label: 'Standard routes',
    value: DeliveryRouteAssignment.REGULAR,
  },
  {
    label: 'Same Day Evening (6PM - 9PM)',
    value: DeliveryRouteAssignment.SAME_DAY_EVENING,
  },
  {
    label: 'Hospital',
    value: DeliveryRouteAssignment.HOSPITAL,
  },
];

export const getDeliveryRouteAssignmentFromKey = (key: string) => {
  const route = DeliveryRouteAssignments.find((route) => route.value === key);
  return route;
};

export const getDeliveryRouteAssignmentNameFromKey = (key: string) => {
  const route = DeliveryRouteAssignments.find((route) => route.value === key);
  return route?.label ?? 'Unknown';
};
